import { useState } from 'react'
import * as actions from '$actions'
import { debounceAsync } from '@utils'
import { useQuery } from '@tanstack/react-query'
import ServiceUsageTrackingHeader from '$routes/Services/widgets/ServiceUsageTracking/ServiceUsageTrackingHeader'
import ServiceUsageTrackingTable from '$routes/Services/widgets/ServiceUsageTracking/ServiceUsageTrackingTable'

const LIMIT = 100

const refreshUsageTrackings = debounceAsync((query, offset, limit) => {
    
    try {
        
        // Don't offset results if searching
        offset = query?.length ? 0 : offset
        
        return actions.getServiceUsageTrackings(query, offset, limit)
        
    } catch (e) {
        
        console.error('const refreshUsageTrackings', e)
        
    }
    
}, 1000)

const ServiceUsageTracking = () => {
    
    const [query, setQuery] = useState('')
    const [pageIndex, setPageIndex] = useState(0)
    const [queryOffset, setQueryOffset] = useState(0)
    const [queryLimit, setQueryLimit] = useState(100)
    
    const { data: usageTrackings, error, isLoading, refetch } = useQuery({
        queryKey: ['usageTrackings', query, pageIndex],
        queryFn: () => refreshUsageTrackings(query, LIMIT * pageIndex, LIMIT),
        enabled: true,
        retry: 0,
    })
    
    return (
        
        <div className="ServiceUsageTracking">
            
            <ServiceUsageTrackingHeader
                query={query}
                setQuery={setQuery}
                queryOffset={queryOffset}
                setQueryOffset={setQueryOffset}
                queryLimit={queryLimit}
                setQueryLimit={setQueryLimit}
                refetch={refetch} />
            
            {error && (
                <div className="alert alert-danger">
                    {error}
                </div>
            )}
            
            {isLoading && (
                <div className="flex justify-center p-4 text-center">
                    <div className="loading loading-spinner" />
                </div>
            )}
            
            <ServiceUsageTrackingTable
                usageTrackings={usageTrackings}
                itemsPerPage={LIMIT}
                forcePage={pageIndex}
                setPageIndex={setPageIndex} />
        
        </div>
        
    )
    
}

export default ServiceUsageTracking
