import { useState, useMemo, useEffect } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import * as appStore from '@store'
import { debounceAsync } from '@utils'
import useSortableTableHeaders from '@hook/useSortableTableHeaders'
import { toast } from 'react-toastify'

import SimplePager from '@components/SimplePager'
import OrgsTable from '$components/OrgsTable'
import ManageOrgCreditsModal from '$components/Modals/ManageOrgCreditsModal'
import AssignOrganizationChecksModal from '$components/Modals/AssignOrganizationChecksModal'

import './Organizations.css'
import CircleSpinner from '@components/shared/CircleSpinner'

const LIMIT = 50

const refreshOrganizations = debounceAsync((query, offset, limit) => {
    
    try {
        
        // @todo this is mess
        // Don't offset results if searching
        // offset = !query?.length ? Math.round(pageIndex * collectionsPerPage) : offset
        offset = query?.length ? 0 : offset
        
        return actions.getOrganizations(query, offset, limit)
        
    } catch (e) {
        
        console.error('const refreshOrganizations', e)
        toast.error('Failed to fetch organizations')
        
    }
    
}, 1000)

const Organizations = () => {
    
    const [count, setCount] = useState(0)
    const [query, setQuery] = useState('')
    const [selectedOrganization, setSelectedOrganization] = useState(null)
    const [modalAssignOrganizationOpen, setModalAssignOrganizationOpen] = useState(false)
    
    const remoteConfig = useWireValue(appStore.remoteConfig)
    const loading = useWireValue(store.isFetchingOrgs)
    const [organizations, setOrganizations] = useWireState(store.organizations)
    const [orgsPage, setOrgsPage] = useWireState(store.orgsPage)
    
    const headers = useMemo(() => (
        [
            ['id', 'ID'],
            ['name', 'Name'],
            ['createdAt', 'Created'],
            ['members.length', 'Members'],
            remoteConfig?.payments?.enabled ? ['credits', 'Credits'] : null,
        ].filter(it => Boolean(it))
    ), [remoteConfig])
    
    const filteredOrgs = useMemo(() => {
        
        if (!query?.length) return organizations
        
        return organizations.filter(it => {
            
            const fields = ['id', 'name']
            
            const hasFields = fields.some(field => {
                return it[field]
                    ?.toLowerCase()
                    ?.includes(query.toLowerCase())
            })
            
            /* const hasMembers = it.members?.some(it => {
                return query?.length > 0 && it?.email?.toLowerCase()?.includes(query?.toLowerCase())
            }) */
            
            return hasFields /* || hasMembers */
            
        })
        
    }, [query, organizations])
    
    const onOrgUpdated = data => {
        
        setOrganizations(prev => ([...prev]).map(it => {
            
            if (it.id !== data.id) return it
            
            return {
                ...it,
                ...data,
            }
            
        }))
        
    }
    
    const {
        orderField,
        orderDirection,
        onHeaderClick,
    } = useSortableTableHeaders(headers)
    
    useEffect(() => { 
        refreshOrganizations(query, LIMIT * orgsPage, LIMIT)
            .then(e => setCount(e.data.length)) 
    }, [query, orgsPage])
    
    useEffect(() => { actions.getAnnotators() }, [])
    
    return (
        
        <div className="Organizations">
            
            <header className="flex items-baseline justify-between content-baseline">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary">
                        Organizations ({count})
                    </h4>
                </div>
                
                <div className="flex items-baseline justify-end content-baseline">
                    
                    <div className="py-2">
                        <input
                            type="text"
                            autoComplete="off"
                            value={query}
                            placeholder="Filter organizations..."
                            onKeyUp={e => e.key === 'Escape' && setQuery('')}
                            onChange={e => setQuery(e.target.value)} />
                    </div>
                    
                    {count > LIMIT && (
                        <SimplePager
                            className="ml-2"
                            pages={count / LIMIT}
                            page={orgsPage}
                            setPage={setOrgsPage} />
                    )}
                
                </div>
            
            </header>
            
            {loading && <CircleSpinner className="absolute-centered" />}
            
            <OrgsTable
                orgs={filteredOrgs}
                headers={headers}
                orderField={orderField}
                orderDirection={orderDirection}
                paymentsEnabled={remoteConfig?.payments?.enabled}
                onHeaderClick={onHeaderClick}
                onAssignAnnotatorClick={organization => {
                    setSelectedOrganization(organization)
                    setModalAssignOrganizationOpen(true)
                }} />
            
            <ManageOrgCreditsModal onOrgUpdated={onOrgUpdated} />
            
            <AssignOrganizationChecksModal
                open={modalAssignOrganizationOpen}
                setOpen={setModalAssignOrganizationOpen}
                organization={selectedOrganization} />
        
        </div>
        
    )
    
}

export default Organizations
