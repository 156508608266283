import useCreateServiceApiKeyModal from './UpsertServiceApiKeyModalViewModel'
import Modal from '$components/Modal'
import { FaTimes } from 'react-icons/fa'
import CircleSpinner from '@components/shared/CircleSpinner'
import AutocompleteInput from '@components/shared/AutocompleteInput/AutocompleteInput'

const UpsertServiceApiKeyModal = ({
    open,
    setOpen,
    existingServiceApiKey,
    
    ...props
}) => {
    
    const vm = useCreateServiceApiKeyModal({
        open,
        setOpen,
        existingServiceApiKey,
    })
    
    return (
        
        <Modal
            className="CreateServiceApiKeyModal overflow-x-auto"
            contentClassName="flex flex-col gap-4"
            style={{ maxWidth: '80%', maxHeight: '80%' }}
            open={open}
            setOpen={setOpen}
            title={vm.modalLabels.title}
            actionLabel={vm.modalLabels.saveButton}
            actionDisabled={!vm.serviceApiKey.name?.length || vm.loading}
            actionOnClick={vm.onConfirmServiceApiKeyClick}
            showCancel={false}
            {...props}>
            
            {vm.loading && (
                <div className="w-full">
                    <CircleSpinner
                        className="absolute-centered"
                        message={vm.loadingMessage} />
                </div>
            )}
            
            {!vm.loading && (
                <div className="p-3 flex justify-between gap-8">
                    
                    <div className="w-full">
                        <p className="font-bold">
                            Service Key Name
                        </p>
                        <input
                            className="w-full"
                            type="text"
                            value={vm.serviceApiKey.name}
                            placeholder="My Service API Key"
                            onChange={vm.updateServiceApiKey('name')}/>
                    </div>
                    
                    <div className="w-full">
                        <p className="font-bold">
                            Select an organization <i>(optional)</i>
                        </p>
                        {vm.selectedOrg?.id && (
                            <div className="flex items-center gap-4">
                                <p>Selected: {vm.selectedOrg?.name}</p>
                                <button
                                    className="btn btn-ghost btn-sm"
                                    title="Clear selection"
                                    onClick={() => vm.setSelectedOrg(vm.ORG_NONE)}>
                                    <FaTimes className="text-sm"/>
                                </button>
                            </div>
                        )}
                        <div className="relative flex items-center gap-4">
                            {vm.orgsSearch.hasOrgs
                                ? <AutocompleteInput
                                    items={[vm.ORG_NONE, ...vm.orgsSearch.orgs.data]}
                                    onChange={vm.setSelectedOrg}/>
                                : <p><i>Fetching organizations...</i></p>
                            }
                        </div>
                    </div>
                
                </div>
            )}
            
            {!vm.loading && (<>
                
                <div className="p-3 rounded">
                    <p>
                        <span className="font-bold">Allowed Domains</span>
                        {' '}
                        <i className="text-sm text-gray-500">
                            (comma-separated)
                        </i>
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={vm.serviceApiKey.allowedDomains}
                        placeholder="https://api.acme.org"
                        onChange={vm.updateServiceApiKey('allowedDomains', true)} />
                </div>
                
                <div className="p-3 rounded">
                    <p>
                        <span className="font-bold">Allowed Endpoints</span>
                        {' '}
                        <i className="text-sm text-gray-500">
                            (comma-separated)
                        </i>
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={vm.serviceApiKey.allowedEndpoints}
                        placeholder="/acme"
                        onChange={vm.updateServiceApiKey('allowedEndpoints', true)} />
                </div>
            
            </>)}
            
            {!vm.loading && (<>
                
                <div className="p-3 rounded">
                    <p>
                        <span className="font-bold">Result Email(s)</span>
                        {' '}
                        <i className="text-sm text-gray-500">
                            (comma-separated)
                        </i>
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={vm.serviceApiKey.resultEmails}
                        placeholder="notifications@domain.com"
                        onChange={vm.updateServiceApiKey('resultEmails', true)} />
                </div>
                
                <div className="p-3 rounded">
                    <p>
                        <span className="font-bold">Result Webhook URL(s)</span>
                        {' '}
                        <i className="text-sm text-gray-500">
                            (comma-separated)
                        </i>
                    </p>
                    <input
                        className="w-full"
                        type="text"
                        value={vm.serviceApiKey.resultWebhookUrls}
                        placeholder="https://webhook.site/callback"
                        onChange={vm.updateServiceApiKey('resultWebhookUrls', true)} />
                </div>
            
            </>)}
            
            {/* <div>
                <pre><code>{JSON.stringify(existingServiceApiKey, null, 4)}</code></pre>
            </div> */}
        
        </Modal>
        
    )
    
}

export default UpsertServiceApiKeyModal
