import { memo, useState } from 'react'
import { formatCurrency, snippet } from '@utils'
import { formatDateTime, relativeTime } from '@utils/date'
import cn from 'classnames'
import Pagination from '@components/shared/Pagination'
import Toggle from '$components/Forms/Toggle'

const ServiceUsageTrackingTable = ({
    usageTrackings,
    itemsPerPage,
    forcePage,
    setPageIndex,
}) => {
    
    const [isDataExpanded, setIsDataExpanded] = useState(false)
    
    return (
        
        <table className="table-auto w-full border-collapse mt-4">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Service API Key ID</th>
                    <th>Created</th>
                    <th>Action/Event</th>
                    <th>Organization</th>
                    <th>User ID</th>
                    <th>Object ID</th>
                    <th>Cost</th>
                    <th>
                        <div className="flex items-center content-center justify-center">
                            <div>Data</div>
                            <div>
                                <Toggle
                                    className="cursor-pointer"
                                    checked={isDataExpanded}
                                    onChange={setIsDataExpanded} />
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {usageTrackings?.rows?.map(usage => {
                    
                    const data = usage.data
                        ? isDataExpanded
                            ? JSON.stringify(usage.data, null, 2)
                            : `${JSON.stringify(usage.data).substring(0, 20)}...`
                        : 'N/A'
                    
                    return (
                        
                        <tr key={usage.id}>
                            <td title={usage.id}>{snippet(usage.id)}</td>
                            <td title={usage.serviceApiKeyId}>{snippet(usage.serviceApiKeyId)}</td>
                            <td title={formatDateTime(usage.createdAt, 'YYYY-MM-DD hh:mm')}>
                                {relativeTime(usage.createdAt)}
                            </td>
                            <td>{usage.actionOrEventName}</td>
                            <td>{usage.serviceApiKey.organization?.name || '-'}</td>
                            <td>{usage.userId || 'N/A'}</td>
                            <td>{usage.objectId || 'N/A'}</td>
                            <td><code>{formatCurrency(usage.cost)}</code></td>
                            <td title={isDataExpanded ? '' : JSON.stringify(usage.data, null, 2)}>
                                <pre>
                                    <code className={cn('break-all text-sm', {
                                        'opacity-50': !isDataExpanded,
                                    })}>
                                        {data}
                                    </code>
                                </pre>
                            </td>
                        </tr>
                        
                    )
                })}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={9}>
                        <div className="flex justify-center">
                            <Pagination
                                count={usageTrackings?.count || 1}
                                itemsPerPage={itemsPerPage}
                                pageRangeDisplayed={3}
                                forcePage={forcePage}
                                setPageIndex={setPageIndex}/>
                        </div>
                    </td>
                </tr>
                {/* <tr>
                    <td colSpan={7}>
                        <div className="">
                            <pre>
                                <code>
                                    {JSON.stringify({ pageIndex, usageTrackings }, null, 2)}
                                </code>
                            </pre>
                        </div>
                    </td>
                </tr> */}
            </tfoot>
        </table>
        
    )
    
}

export default memo(ServiceUsageTrackingTable)
