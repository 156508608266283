export const ST_COMMENTS_KEY = 'comments'
export const ST_LD_KEY = 'ld_summarizer'
export const ST_VAL_EXT_KEY = 'value_extractor'
export const ST_CHAINER_KEY = 'chainer'
export const ST_DOCUMENT_EXTRACTOR_KEY = 'document_extractor'

export const SYSTEM_TYPE_LEGAL_DESCRIPTIONS_SUMMARIZER = { key: ST_LD_KEY, value: 'Legal Description' }
export const SYSTEM_TYPE_CHAINER = { key: ST_CHAINER_KEY, value: 'Chainer' }
export const SYSTEM_TYPE_DEED_VALUES = { key: ST_VAL_EXT_KEY, value: 'Value Extractor' }
export const SYSTEM_TYPE_DOCUMENT_EXTRACTOR = { key: ST_DOCUMENT_EXTRACTOR_KEY, value: 'Document Extractor' }

export const SYSTEM_TYPES = [
    
    SYSTEM_TYPE_DEED_VALUES,
    SYSTEM_TYPE_DOCUMENT_EXTRACTOR,
    SYSTEM_TYPE_LEGAL_DESCRIPTIONS_SUMMARIZER,
    SYSTEM_TYPE_CHAINER,
    
]

export const SYSTEM_TYPES_MAP = SYSTEM_TYPES.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {})

export const ENABLED_NEVER = { key: 'never', value: 'Never' }
export const ENABLED_ALWAYS = { key: 'always', value: 'Always' }
export const ENABLED_AUTO = { key: 'auto', value: 'Auto' } 


export const ENABLED_STATUS = [
    ENABLED_NEVER,
    ENABLED_ALWAYS,
    ENABLED_AUTO,
]

export const TARGET_EXAMPLE = { key: 'target', value: 'Target Example' }
export const FULL_EXAMPLE = { key: 'fullExample', value: 'Full Example' }

export const EXAMPLE_TYPES = [
    TARGET_EXAMPLE,
    FULL_EXAMPLE,
]