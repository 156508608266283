import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import * as sysActions from '@actions'
import cn from 'classnames'
import * as store from '$store'

import { useWireState } from '@forminator/react-wire'
import ConfirmationModal from '@/components/shared/ConfirmationModal'
import { ST_VAL_EXT_KEY, SYSTEM_TYPES_MAP } from '@/constants/exampleManager'
import { snippet } from '@/utils'
import CopyToClipboardButton from '$components/CopyToClipboardButton'
import ExampleManagerModal from '@/components/exampleManager/ExampleManagerModal'
import PresetInstructionAndDefinitionModal from './PresetInstructionAndDefinitionModal'
import { useSearchParams } from 'react-router-dom'
import ExampleManagerTabs from './ExampleManagerTabs'
import { Link } from 'react-router-dom'
import PresetDocumentsModal from './PresetDocumentsModal'
import ClonePresetModal from './ClonePresetModal'
import { toast } from 'react-toastify'
import { MdCheckCircle } from 'react-icons/md'



const Presets = () => {
    
    const [searchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    
    const [presets, setPresets] = useWireState(store.presets)
    const [collections, setCollections] = useState([])
    const [selectedPreset, setSelectedPreset] = useState(null)
    const [markedDocuments, setMarkedDocuments] = useState([])
    const [isInserting, setIsInserting] = useState(false)
    
    const [showDocumentsModal, setShowDocumentsModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showEditExampleModal, setShowEditExampleModal] = useState(false)
    const [showPresetInstructionDefinitionModal, setShowPresetInstructionDefinitionModal] = useState(false)
    const [showChangeClonedPresetName, setShowChangeClonedPresetName] = useState(false)
    const [presetDocument, setPresetDocument] = useState('')
    
    const system = useMemo(() => searchParams.get('system') || ST_VAL_EXT_KEY, [searchParams.get('system')])
    const templateId = useMemo(() => searchParams.get('templateId'), [searchParams.get('templateId')])
    
    
    const arrayToRender = useMemo(() =>
        presets.filter(ex => system === ex.system && templateId === ex.valueExtractorTemplateId),
    [presets, system, templateId])
    
    const deleteItems = async () => {
        await actions.deletePresets(markedDocuments)
    }
    
    const copyToWorkingTable = async (id, name) => {
        setIsInserting(true)
        await actions.copyToWorkingTable(id)
        toast.success(`Version "${name}" copied to the Experimental Table.`)
        setIsInserting(false)
    }
    
    const deployToLiveTable = async () => {
        await actions.deployToLiveTable(selectedPreset.id)
        setPresets(prev => prev.map(p => ({ ...p, activePreset: p.id === selectedPreset.id })))
        toast.success(`Successfully deployed "${selectedPreset.name}" to Live Table`)
    }
    
    useEffect(() => {
        actions.getAllPresets()
        actions.getTemplates()
        
        actions.getEvalCollections().then(collections => {
            setCollections(collections)
        })
        
        sysActions.fetchRunsheets()
    }, [])
    
    return (
        
        <div className="w-full flex flex-col p-4">
            <h3>Version Manager</h3>
            <div className="pt-4 pb-0 pr-2 text-sm breadcrumbs flex flex-row justify-between">
                <ul>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li>
                        <Link to={`/dashboard/example-manager?system=${system}&templateId=${templateId}`}>
                            Template Manager
                        </Link>
                    </li>
                    <li>Version Manager</li>
                </ul>
                
                <div className='flex gap-4'>
                    
                    <button
                        className={cn('btn btn-error', {
                            'opacity-20': markedDocuments.length === 0,
                        })}
                        onClick={() => {
                            deleteItems()
                            setMarkedDocuments([])
                        }}
                        disabled={markedDocuments.length === 0}>Delete</button>
                </div>
            </div>
            
            <ExampleManagerTabs />
            
            <div className="max-h-[calc(100vh-200px)] mt-4">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>System</th>
                            <th>Template</th>
                            <th>Status</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrayToRender.map(p => {
                            return (
                                <tr key={p.id}>
                                    <td className="w-[10px]">
                                        <input
                                            type="checkbox"
                                            checked={markedDocuments.includes(p.id)}
                                            onChange={event => {
                                                if (event.target.checked)
                                                    setMarkedDocuments(prevToDelete =>
                                                        [...prevToDelete, p.id])
                                                else
                                                    setMarkedDocuments(prevToDelete => prevToDelete
                                                        .filter(id => id !== p.id))
                                            }} />
                                    </td>
                                    <td className="w-[120px]">
                                        {snippet(p.id)} <CopyToClipboardButton value={p.id} />
                                    </td>
                                    <td className="cursor-pointer w-[100px]"
                                        onClick={() => {
                                            setSelectedPreset(p)
                                            setShowDocumentsModal(true)
                                        }}>
                                        {p.activePreset &&
                                            <span
                                                className="mr-2 tooltip tooltip-bottom align-middle tooltip-success"
                                                data-tip="Preset deployed to Live Table">
                                                <MdCheckCircle className="text-success" />
                                            </span>
                                        }
                                        {p.name}
                                    </td>
                                    <td className='w-[410px]'>{SYSTEM_TYPES_MAP[p.system] || p.system}</td>
                                    <td className='w-[160px]'>{p.vet?.name}</td>
                                    <td className='w-[100px]'>{p.status}</td>
                                    <td className='w-[90px]'>
                                        <button
                                            className='btn btn-secondary self-start'
                                            onClick={() => {
                                                setSelectedPreset(p)
                                                setShowChangeClonedPresetName(true) }
                                            }
                                            disabled={isInserting}>
                                                Clone Version
                                        </button>
                                    </td>
                                    <td className='w-[220px]'>
                                        <Link
                                            className="btn btn-primary"
                                            to={`/dashboard/example-manager/presets/evaluation-results/${p.id}`}>
                                                View Evaluations
                                        </Link>
                                    </td>
                                    <td className='w-[180px]'>
                                        <button
                                            className="btn btn-secondary"
                                            disabled={isInserting}
                                            onClick={() => {
                                                copyToWorkingTable(p.id, p.name)
                                            }}>
                                                Copy to WorkingTable
                                        </button>
                                    </td>
                                    <td className='w-[180px]'>
                                        <button
                                            className="btn btn-primary"
                                            disabled={isInserting}
                                            onClick={() => {
                                                setSelectedPreset(p)
                                                setShowConfirmationModal(true)
                                            }}>
                                            Deploy LiveTable
                                        </button>
                                    </td>
                                    <td className='w-[80px]'>
                                        <button
                                            className='btn btn-primary self-end'
                                            onClick={() => {
                                                setSelectedPreset(p)
                                                setShowPresetInstructionDefinitionModal(true)
                                            }}>
                                            Definitions
                                        </button>
                                    </td>
                                    <td className='w-[100px]'>
                                        <button 
                                            className='btn btn-primary self-end'
                                            onClick={() => {
                                                setShowDocumentsModal(true)
                                                setSelectedPreset(p)
                                            }}>
                                                Examples
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            
            {showDocumentsModal && (
                <PresetDocumentsModal
                    collections={collections}
                    selectedPreset={selectedPreset}
                    setSelectedPreset={setSelectedPreset}
                    setPresetDocument={setPresetDocument}
                    showDocumentsModal={showDocumentsModal}
                    setShowDocumentsModal={setShowDocumentsModal}
                    setShowChangeClonedPresetName={setShowChangeClonedPresetName}
                    setShowEditExampleModal={setShowEditExampleModal}
                    setShowConfirmationModal={setShowConfirmationModal} />
            )}
            
            {showChangeClonedPresetName && (
                <ClonePresetModal
                    selectedPreset={selectedPreset}
                    showChangeClonedPresetName={showChangeClonedPresetName}
                    setShowChangeClonedPresetName={setShowChangeClonedPresetName}
                    setShowDocumentsModal={setShowDocumentsModal}
                    setIsInserting={setIsInserting}
                    isInserting={isInserting} />
            )}
            
            {showEditExampleModal && (
                <ExampleManagerModal
                    show={showEditExampleModal}
                    handleClose={() => setShowEditExampleModal(false)}
                    example={presetDocument}
                    isPreset={true}>
                
                </ExampleManagerModal>
            )}
            
            {showConfirmationModal && (
                <ConfirmationModal
                    className="max-w-[450px]"
                    showModal={showConfirmationModal}
                    showHideModal={setShowConfirmationModal}
                    title="Deploy to LiveTable"
                    message={<div className="py-4">Confirm deploying <b>"{selectedPreset.name}"</b> to LiveTable?</div>}
                    onConfirm={deployToLiveTable}>
                
                </ConfirmationModal>
            )}
            
            {showPresetInstructionDefinitionModal && (
                <PresetInstructionAndDefinitionModal
                    showPresetInstructionDefinitionModal={showPresetInstructionDefinitionModal}
                    setShowPresetInstructionDefinitionModal={setShowPresetInstructionDefinitionModal}
                    system={selectedPreset.system}
                    presetId={selectedPreset.id} />
            )}
        
        </div>
        
        
    )
}

export default Presets