import { memo } from 'react'
import { FaSyncAlt } from 'react-icons/fa'

const ServiceUsageTrackingHeader = ({
    query,
    setQuery,
    queryOffset,
    setQueryOffset,
    queryLimit,
    setQueryLimit,
    refetch,
}) => {
    
    return (
        
        <header className="flex items-baseline justify-between content-baseline gap-6">
            
            <h4>Service Usage Tracking</h4>
            
            {/* Search field */}
            <div className="flex items-center content-center gap-3 grow">
                <input
                    className="input input-bordered w-full"
                    type="text"
                    value={query}
                    placeholder="Search service usage tracking..."
                    onKeyUp={e => e.key === 'Escape' && setQuery('')}
                    onChange={e => setQuery(e.target.value)}/>
            </div>
            
            {/* Filtering & refresh button */}
            <div className="flex items-center content-center gap-3">
                
                <div className="flex items-center content-center gap-3">
                    
                    <label>Limit</label>
                    <input
                        className="input input-bordered w-16 text-center h-[2.5rem]"
                        type="number"
                        value={queryLimit}
                        onChange={e => setQueryLimit(parseInt(e.target.value, 10))} />
                    
                    <label>Offset</label>
                    <input
                        className="input input-bordered w-16 text-center h-[2.5rem]"
                        type="number"
                        value={queryOffset}
                        onChange={e => setQueryOffset(parseInt(e.target.value, 10))} />
                
                </div>
                
                <button
                    className="btn btn-primary"
                    onClick={refetch}>
                    <FaSyncAlt/>
                </button>
            
            </div>
        
        </header>
        
    )
    
}

export default memo(ServiceUsageTrackingHeader)
