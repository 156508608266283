import * as store from '$store'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import { useWireValue } from '@forminator/react-wire'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import SelectDatasetDocumentsModal from './SelectDatasetDocumentsModal'
import dayjs from 'dayjs'



const PresetEvaluationResult = () => {
    
    const params = useParams()
    
    const preset = useWireValue(store.preset)
    
    const [evalRunsheets, setEvalRunsheets] = useState([])
    const [evalCollections, setEvalCollections] = useState([])
    
    const [selectedDatasetId, setSelectedDatasetId] = useState(null)
    const [selectedBaselinePresetId, setSelectedBaselinePresetId] = useState(null)
    
    const [baselineOptions, setBaselineOptions] = useState([])
    const [baselineChecked, setBaselineChecked] = useState(true)
    
    const [baselineEvaluations, setBaselineEvaluations] = useState([])
    const [selectedPresetEvaluations, setSelectedPresetEvaluations] = useState([])
    const [presetEvaluations, setPresetEvaluations] = useState([])
    const [selectedEvaluation, setSelectedEvaluation] = useState([])
    const [selectedDocumentId, setSelectedDocumentId] = useState('')
    const [selectedDocumentEvaluation, setSelectedDocumentEvaluation] = useState([])
    const [condensedResults, setCondensedResults] = useState([])
    const [selectedEvaluationId, setSelectedEvaluationId] = useState(null)
    
    const [showRawDataModal, setShowRawDataModal] = useState(false)
    const [rawData, setRawData] = useState(null)
    
    const [selectedMetricName, setSelectedMetricName] = useState(null)
    
    const isChainer = useMemo(() => preset?.system === 'chainer', [preset])
    const [isEvaluating, setIsEvaluating] = useState(false)
    
    const [numOfRuns, setNumOfRuns] = useState(0)
    const [dataSetDocuments, setDataSetDocuments] = useState([])
    const [markedDocuments, setMarkedDocuments] = useState([])
    
    const [selectDocumentsModal, setSelectDocumentsModal] = useState(false)
    const [evalResultModal, setEvalResultModal] = useState(false)
    const [baselineName, setBaselineName] = useState('')
    
    const combinedMetrics = [...selectedDocumentEvaluation, 
        ...baselineEvaluations].reduce((uniqueArray, currentItem) => {
        
        const existingItem = uniqueArray.find(
            item => item.metricName === currentItem.metricName 
            && item.category === currentItem.category
            && item.runNumber === currentItem.runNumber,
        )
        
        if (existingItem) {
            existingItem.otherMetricValue = currentItem.metricValue
            existingItem.otherRawResults = currentItem.rawResults
            existingItem.metricName = currentItem.metricName
        } else {
            uniqueArray.push(currentItem)
        }
        
        return uniqueArray
    }, [])
    
    const presetValues = combinedMetrics.map(k => {
        const val = selectedDocumentEvaluation?.find(l => (
            l.metricName === k.metricName 
            && l.category === k.category
            && l.runNumber === k.runNumber))
        
        const final = {
            value: val || '-',
            runNumber: k.runNumber,
            otherMetricValue: k.otherMetricValue ? parseFloat(k.otherMetricValue) : '-',
            originalMetricValue: val?.metricValue ? parseFloat(val?.metricValue) : '-',
        }
        
        return final
    })
    
    const baselineValues = combinedMetrics.map(k => {
        const val = baselineEvaluations.find(l => (l.metricName === k.metricName && l.category === k.category))
        
        const final = { value: val || '-' }
        
        return final
    })
    
    const fetchBaselinePresetData = async baselinePresetId => {
        
        setSelectedBaselinePresetId(baselinePresetId)
        
        if (!baselinePresetId)
            return setBaselineEvaluations([])
        
        const bEvaluation = await actions.getBaselineEvaluations(baselinePresetId)
        
        
        // const evaluation = await actions.getEvalFromPreset(baselinePresetId,
        //     selectedDatasetId, isChainer)
        
        setBaselineEvaluations(bEvaluation)
    }
    
    const fetchPresetEvaluations = async datasetId => {
        
        setSelectedDatasetId(datasetId)
        
        if (!datasetId) {
            setBaselineOptions([])
            setSelectedPresetEvaluations([])
            return
        }
        
        const dsDocuments = await actions.getDocuments(datasetId)
        
        setDataSetDocuments(dsDocuments.data.rows)
        const baselineOpts = await actions.getBaselines(preset.id, datasetId, preset.system)
        
        setBaselineOptions(baselineOpts)
        
        const pEvaluations = await actions.getAllPresetEvaluations(preset.id, datasetId)
        
        setPresetEvaluations(pEvaluations)
        
        // const evaluations = await actions.getEvalFromPreset(preset.id, datasetId, isChainer)
        
        // setSelectedPresetEvaluations(evaluations)
        
    }
    
    const evaluatePreset = async () => {
        
        await actions.createEval(preset.id,
            !isChainer ? selectedDatasetId : undefined, // collectionId being the datasetId
            isChainer ? selectedDatasetId : undefined, // runsheetId being the datasetId
            numOfRuns, markedDocuments) 
        
        
        toast.success('Evaluation started.')
        
    }
    
    const checkIfEvaluationWasCompleted = async () => {
        
        const intervalid = setInterval(() => {
            
            actions.checkIfEvaluationWasCompleted(preset.id).then(ev => {
                if (ev) {
                    
                    actions.getEvalFromPreset(preset.id, selectedDatasetId, isChainer)
                        .then(e => setSelectedPresetEvaluations(e))
                    
                    setIsEvaluating(false)
                    
                    clearInterval(intervalid)
                }
            })
            
        }, 5000)
        
    }
    
    useEffect(() => {
        
        if (!params?.id) return
        
        actions.findPresetById(params.id)
        
    }, [params.id])
    
    useEffect(() => {
        
        if (isChainer) {
            
            actions.getEvalRunsheets().then(run => {
                setEvalRunsheets(run)
            })
            
        } else {
            
            actions.getEvalCollections().then(col => {
                setEvalCollections(col)
            })
            
        }
        
    }, [isChainer])
    
    useEffect(() => {
        setMarkedDocuments(dataSetDocuments.map(pd => pd.id))
    }, [dataSetDocuments])
    
    useEffect(() => {
        
        const evalDocuments = []
        
        selectedEvaluation.forEach(se => {
            if (se.documentId === selectedDocumentId) {
                evalDocuments.push(se)
            }
        })
        
        setSelectedDocumentEvaluation(evalDocuments)
        
    }, [selectedDocumentId])
    
    return (
        
        <div className='p-4 w-full'>
            <div>
                <h3>
                    {`Evaluation - ${preset?.name}`}
                </h3>
                <div className='flex flex-row gap-2'>
                    <p>
                        {preset?.system}
                    </p>
                    <p> - </p>
                    <p>
                        {preset?.vet?.name}
                    </p>
                </div>
            </div>
            
            <div className='pt-12 flex justify-between'>
                <div className='w-4/12 flex content-center gap-2'>
                    <div className='flex flex-col gap-2 flex-1'>
                        <label htmlFor="dataset" className='self-center'>
                            Dataset
                        </label>
                        <select
                            id="dataset"
                            className="select select-bordered select-sm"
                            value={selectedDatasetId}
                            onChange={e => fetchPresetEvaluations(e.target.value) }>
                            <option value=""></option>
                            {(preset.system === 'chainer' ? evalRunsheets : evalCollections).map(it => (
                                <option key={it.id} value={it.id}> 
                                    {it.name}
                                </option>
                            ))}
                        
                        </select>
                    </div>
                    <div className='flex flex-col justify-between'>
                        <label htmlFor="numberOfRuns">Nº of runs</label>
                        <input 
                            id="numberOfRuns" 
                            type="number" 
                            className="input input-bordered h-10 w-20 self-center 
                            [appearance:textfield] 
                            [&::-webkit-outer-spin-button]:appearance-none 
                            [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={e => setNumOfRuns(parseInt(e.target.value, 10))}
                            value={numOfRuns}/>
                    </div>
                    <div className="content-end">
                        <button
                            className="btn btn-primary"
                            disabled={!selectedDatasetId || numOfRuns <= 0 || isNaN(numOfRuns)}
                            onClick={() => {
                                setIsEvaluating(true)
                                evaluatePreset()
                                checkIfEvaluationWasCompleted()
                            }}>
                            {selectedPresetEvaluations.length ? 'Re-Evaluate' : 'Evaluate'}
                        </button>
                        {isEvaluating && (
                            <div className="flex flex-row gap-2">
                                <p 
                                    className="text-blue-600 text-md font-thin self-center "> Evaluating 
                                </p>
                                <span className="loading loading-spinner loading-xs text-blue-600" /> 
                            </div>
                            
                        )}
                    </div>
                </div>
                
                <div className="flex flex-col gap-2 w-4/12">
                    <label htmlFor="baseline" className="self-center">
                        Baseline
                    </label>
                    <div className="flex gap-2">
                        <input
                            type="checkbox"
                            className="checkbox self-center"
                            disabled={!selectedDatasetId}
                            onChange={() => setBaselineChecked(!baselineChecked)}
                            checked={baselineChecked && selectedBaselinePresetId}/>
                        <select
                            id="baseline"
                            className="select select-bordered select-sm w-full"
                            onChange={e => fetchBaselinePresetData(e.target.value)}
                            disabled={!selectedDatasetId}>
                            <option value=""></option>
                            {baselineOptions.map(it => (
                                <option key={it.id} value={it.id}> 
                                    {it.baselineName}    
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            
            </div>
            <div className='my-2'>
                <p className='text-sm flex flex-row italic underline' onClick={() => setSelectDocumentsModal(true)}>
                    click here to select which documents to evaluate (all selected by default) 
                </p>
                <div>
                    {selectDocumentsModal && (
                        <SelectDatasetDocumentsModal
                            selectDocumentsModal={selectDocumentsModal}
                            setSelectDocumentsModal={setSelectDocumentsModal}
                            dataSetDocuments={dataSetDocuments}
                            setMarkedDocuments={setMarkedDocuments}
                            markedDocuments={markedDocuments}/>
                    )}
                </div> 
            </div>
            <div className='flex flex-row gap-4'>
                <div className='flex flex-col '>
                    <label htmlFor="presetEvaluations"> Evaluations </label>
                    <select  
                        id="evaluations"
                        className="select select-bordered select-sm "
                        onChange={async e => {
                            await actions.condensedEvaluationResults(e.target.value).then(e => setCondensedResults(e))
                            setSelectedEvaluation(await actions.getAllPresetEvaluationResult(e.target.value))
                            setSelectedEvaluationId(e.target.value)
                        }}>
                        <option></option>
                        {presetEvaluations?.map(pe => (
                            <option key={pe.id} value={pe.id}> 
                                {dayjs(pe.createdAt).format('YYYY-MM-DD - HH:mm:ss')}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='flex flex-row gap-4'>
                    
                    <div className='flex flex-col '>
                        <label htmlFor="baselineName"> Baseline Name </label>
                        <input 
                            type="text" 
                            placeholder='Baseline Name' 
                            onChange={e => setBaselineName(e.target.value)}
                            value={baselineName}/>
                    </div>
                    <button 
                        className='btn btn-primary self-end'
                        disabled={!selectedEvaluationId}
                        onClick={async () => {
                            const newBaseline = await actions.createBaseline(baselineName, selectedEvaluationId)
                            
                            const oldBaselines = baselineOptions
                            const newBaselines = [newBaseline, ...oldBaselines]
                            
                            setBaselineOptions(newBaselines)
                        }}>
                        Make Baseline
                    </button>
                </div>
            </div>
            
            <div className='mt-4 flex flex-col justify-between'>
                <table className="table table-auto">
                    <thead>
                        <th>Doc Name</th>
                        <th>Category</th>
                        <th>Avg Metric Value</th>
                        <th>Amount Runs</th>
                    </thead>
                    <tbody>
                        {condensedResults.map(cr => {
                            return (
                                <tr key={cr.id}>
                                    <td 
                                        onClick={async () => {
                                            setSelectedDocumentId(cr.documentId)
                                            setEvalResultModal(true)
                                        }}
                                        className='cursor-pointer'>{cr.documentName}</td>
                                    <td>{cr.category}</td>
                                    <td>{cr.avgMetricValue}</td>
                                    <td>{cr.totalResults}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            
            {evalResultModal && (
                <CustomModal
                    open={evalResultModal}
                    modalHandler={() => setEvalResultModal(false)}
                    className="max-w-[50vw] max-h-[calc(100vh-100px)]">
                    <ModalHeader>
                        <h1>
                            Evaluation Results
                        </h1>
                    </ModalHeader>
                    <ModalBody>
                        <div className="mt-12">
                            <div>
                                <div className='flex gap-4 justify-end'>
                                    <input
                                        type="checkbox"
                                        className="checkbox self-center"
                                        disabled={!selectedDatasetId}
                                        onChange={() => setBaselineChecked(!baselineChecked)}
                                        checked={baselineChecked && selectedBaselinePresetId}/>
                                    <p>Show Baseline?</p>
                                </div>
                                <div className="flex justify-center p-4 rounded-box overflow-auto w-full">
                                    <div className="w-full">
                                        <h4 className="text-center">
                                            Metric
                                        </h4>
                                        <ul className="list-none flex flex-col">
                                            {combinedMetrics.map(k => 
                                                <li 
                                                    className="border text-center content-center h-auto cursor-pointer" 
                                                    onClick={() => {
                                                        setShowRawDataModal(true)
                                                        setSelectedMetricName(k.metricName)
                                                        setRawData([k.otherRawResults, k.rawResults])
                                                    }}>
                                                    <div 
                                                        className='h-8 content-center'> {k.metricName} - 
                                                        <span className='font-bold'> 
                                                            {k.category || 'llm_score'} 
                                                        </span> 
                                                    </div>                                       
                                                </li>,
                                            )}
                                        </ul>
                                    </div>
                                    <div className="w-2/12">
                                        <h4 className="text-center">
                                            Baseline
                                        </h4>
                                        <ul className={cn({
                                            'hidden': !baselineChecked,
                                        }, 'list-none flex flex-col ')}>
                                            {baselineValues?.map(be => (
                                                <li key={be.id} className="border text-center content-center">
                                                    <div className="h-8 content-center"> 
                                                        {be.value.metricValue || be.value} 
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="w-2/12">
                                        <h4 className="text-center">
                                            Result
                                        </h4>
                                        <ul  className="list-none flex flex-col ">
                                            {presetValues.map(spe => (
                                                <li key={spe.id} className={cn('border text-center content-center', {
                                                    // "bg-green-600": spe.originalMetricValue > spe.otherMetricValue,
                                                    // "bg-red-600": spe.originalMetricValue < spe.otherMetricValue,
                                                })}>
                                                    <div className="h-8 content-center"> 
                                                        {spe.value.metricValue || spe.value} 
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='w-2/12'>
                                        <h4 className='text-center'>
                                            Run
                                        </h4>
                                        <ul className='list-none flex flex-col'>
                                            {presetValues.map(pv => (
                                                <li className="border text-center content-center">
                                                    <div className='h-8 content-center'>
                                                        {pv.value.runNumber}
                                                    
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button 
                            className='btn btn-primary btn-outline'
                            onClick={() => setEvalResultModal(false)}>
                            Close
                        </button>
                    </ModalFooter>
                </CustomModal>
            )}
            
            {showRawDataModal && (
                <CustomModal
                    open={showRawDataModal}
                    modalHandler={() => setShowRawDataModal(false)}
                    className="max-w-[50vw]">
                    <ModalHeader>
                        {rawData[0] ? 
                            `${selectedMetricName} - ${rawData[0]?.Category}` 
                            : `${selectedMetricName} - ${rawData[1]?.Category}`}
                    </ModalHeader>
                    <ModalBody>
                        <div className='flex justify-around p-4 overflow-auto'>
                            <div className='flex flex-col gap-2 max-h-[300px]'>
                                <h4>Baseline: </h4>
                                <div className='whitespace-pre p-4 bg-base-100 rounded '>
                                    {JSON.stringify(rawData[0], null, 4)}
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 max-h-[300px]'>
                                <h4>Result: </h4>
                                <div className='whitespace-pre p-4 bg-base-100 rounded'>
                                    {JSON.stringify(rawData[1], null, 4)}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button 
                            className='btn btn-primary btn-outline'
                            onClick={() => setShowRawDataModal(false)}>
                                Close
                        </button>
                    </ModalFooter>
                </CustomModal>
            )}
        
        
        </div>
        
    )
}

export default PresetEvaluationResult