import { useQuery } from '@tanstack/react-query'
import { useWireValue } from '@forminator/react-wire'

const useGlobalStateQuery = (wire, options) => {
    
    const query = useQuery(options)
    const value = useWireValue(wire)
    
    return {
        ...query,
        data: query.data ?? value,
    }
    
}

export default useGlobalStateQuery
