import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useServicesViewModel from './ServicesViewModel'
import ServiceApiKeys from './widgets/ServiceApiKeys'
import TestServiceApi from './widgets/TestServiceApi'
import ServiceUsageTracking from './widgets/ServiceUsageTracking'
import ServiceUsageReports from './widgets/ServiceUsageReports'
import './Services.css'

const Services = () => {
    
    const vm = useServicesViewModel()
    
    const servicesLinks = useMemo(() => ({
        '/dashboard/services': ['SERVICE API KEYS', <ServiceApiKeys vm={vm}/>],
        '/dashboard/services/test': ['TEST SERVICE API', <TestServiceApi serviceApiKeys={vm.serviceApiKeys}/>],
        '/dashboard/services/usage-tracking': ['SERVICE USAGE TRACKING', <ServiceUsageTracking />],
        '/dashboard/services/reports': ['REPORTS', <ServiceUsageReports />],
    }), [vm])
    
    return (
        
        <div className="Services">
            
            <header className="w-full flex items-baseline justify-between content-baseline">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary">
                        Services
                    </h4>
                </div>
                
                <div className="flex items-baseline justify-end content-baseline gap-4">
                    <div className="tabs tabs-boxed" role="tablist">
                        {Object.keys(servicesLinks).map(url => {
                            
                            const [label] = servicesLinks[url]
                            
                            return (
                                <Link
                                    key={url}
                                    className={url === vm.tab ? 'tab tab-active' : 'tab'}
                                    role="tab"
                                    to="/dashboard/services"
                                    onClick={e => {
                                        e.preventDefault()
                                        vm.setTab(url)
                                    }}>
                                    {label}
                                </Link>
                            )
                            
                        })}
                    </div>
                </div>
            </header>
            
            <div className="flex flex-col gap-4 mt-4">
                
                {/* <Outlet vm={vm} serviceApiKeys={vm.serviceApiKeys} /> */}
                
                {/* <TestServiceApi serviceApiKeys={vm.serviceApiKeys}  />
                <ServiceApiKeys vm={vm} /> */}
                
                {servicesLinks[vm.tab][1]}
            
            </div>
        
        </div>
        
    )
    
}

export default Services
