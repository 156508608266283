import api from '$lib/api'
import * as store from '$store'
import { createQueryString } from '@utils'
/* import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@utils' */

export const fetchServiceApiKeys = async () => {
    
    const res = await api.get('/admin/services/api-keys')
    
    if (res.data)
        store.serviceApiKeys.setValue(res.data)
    
    return res.data
    
}

export const createServiceApiKey = async data => {
    
    const res = await api.post('/admin/services/api-keys', data)
    
    return res.data
    
}

export const updateServiceApiKey = async (id, data) => {
    
    const res = await api.patch(`/admin/services/api-keys/${id}`, data)
    
    return res.data
    
}

export const deleteServiceApiKey = async id => {
    
    const res = await api.delete(`/admin/services/api-keys/${id}`)
    
    return res.data
    
}

export const getServiceUsageTrackings = async (query, offset, limit, orderBy, orderDirection) => {
    
    const qs = createQueryString({
        query,
        offset,
        limit,
        orderBy,
        orderDirection,
    })
    
    const res = await api.get(`/admin/services/usage-trackings${qs}`)
    
    return res.data
    
}

export const getServiceUsageTrackingsActionOrEventTypes = async () => {
    
    const res = await api.get('/admin/services/usage-trackings-actions-or-events')
    
    return res.data
    
}

export const getServiceUsageReport = async (
    organizationId,
    actionOrEventName,
    year,
    month,
    orderBy,
    orderDirection,
    download = undefined,
) => {
    
    const qs = createQueryString({
        organizationId,
        actionOrEventName,
        year,
        month,
        orderBy,
        orderDirection,
        download,
    })
    
    const params = {}
    
    if (download)
        params.responseType = 'blob'
    
    return await api.get(`/admin/services/report${qs}`, params)
    
}
