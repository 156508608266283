import { useState, useCallback, useMemo, useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { debounceAsync } from '@utils'

import CircleSpinner from '@components/shared/CircleSpinner'
import CollectionsTable from '$components/CollectionsTable'
import AssignCollectionModal from '$components/Modals/AssignCollectionModal'
import Pagination from '@components/shared/Pagination'

import './Collections.css'
import Checkbox from '$components/Forms/Checkbox'
import useGlobalStateQuery from '@hook/useGlobalStateQuery'

const collectionsPerPage = 30

const refreshCollections = debounceAsync((
    pageIndex,
    orderField,
    orderDirection,
    query,
    onlyMyCollections,
    onlyParseCollections,
) => {
    
    const opts = {
        limit: collectionsPerPage,
        orderField,
        orderDirection,
        query,
        onlyMyCollections,
        onlyParseCollections,
    }
    
    // Don't offset results if searching
    if (!query?.length)
        opts.offset = Math.round(pageIndex * collectionsPerPage)
    
    return actions.getCollectionsWithStats(opts).then(res => res.data)
    
})

const Collections = () => {
    
    // const [loading, setLoading] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [orderField, setOrderField] = useState('createdAt')
    const [orderDirection, setOrderDirection] = useState('desc')
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [query, setQuery] = useState('')
    const [onlyMyCollections, setOnlyMyCollections] = useState(false)
    const [onlyParseCollections, setOnlyParseCollections] = useState(false)
    const [modalAssignCollectionOpen, setModalAssignCollectionOpen] = useState(false)
    
    const user = useWireValue(store.user)
    
    const { data: collections, error, isLoading } = useGlobalStateQuery(store.collections, {
        queryKey: [
            'collections',
            pageIndex,
            orderField,
            orderDirection,
            query,
            onlyMyCollections,
            onlyParseCollections,
        ],
        queryFn: () => refreshCollections(
            pageIndex,
            orderField,
            orderDirection,
            query,
            onlyMyCollections,
            onlyParseCollections,
        ),
        enabled: true,
        retry: 0,
    })
    
    /** @type {Collection[]} */
    const filteredCollections = useMemo(() => {
        
        let items = collections?.rows ?? []
        
        if (query?.length)
            items = items.filter(it => it.name.toLowerCase().includes(query))
        
        if (onlyMyCollections)
            items = items.filter(it => it.creatorId === user.id)
        
        if (onlyParseCollections)
            items = items.filter(it => it.isParse === true)
        
        return items
        
    }, [user, collections, query, onlyMyCollections, onlyParseCollections])
    
    const updateOrderField = useCallback(key => {
        
        if (key === orderField) {
            setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
        } else {
            setOrderField(key)
            setOrderDirection('desc')
        }
        
    }, [orderField, orderDirection])
    
    useEffect(() => { actions.getAnnotators() }, [])
    
    if (error) return (
        <div className="Collections">
            Error: {error?.message || JSON.stringify(error || {})}
        </div>
    )
    
    if (!collections) return (
        <div className="Collections">
            <CircleSpinner className="absolute-centered" />
        </div>
    )
    console.log('render', collections)
    return (
        
        <div className="Collections">
            
            <header className="flex items-baseline justify-between mb-3 content-baseline">
                
                <h4 className="!mb-0 text-xl text-dashboard-primary">
                    Collections
                </h4>
                
                {(collections.count > collectionsPerPage) && (
                    <Pagination
                        count={collections?.count}
                        itemsPerPage={collectionsPerPage}
                        setPageIndex={setPageIndex} />
                )}
                
                <div className="flex justify-end items-center content-center gap-3">
                    <Checkbox
                        label="Only My Collections"
                        checked={onlyMyCollections}
                        onChange={() => setOnlyMyCollections(!onlyMyCollections)} />
                    <Checkbox
                        label="Only Parse Collections"
                        checked={onlyParseCollections}
                        onChange={() => setOnlyParseCollections(!onlyParseCollections)} />
                    <input
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder="Filter collections..."
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
                </div>
            
            </header>
            
            {isLoading && <CircleSpinner className="absolute-centered" />}
            
            <CollectionsTable
                collections={filteredCollections}
                orderField={orderField}
                orderDirection={orderDirection}
                onRefreshCollections={() => refreshCollections(
                    pageIndex,
                    orderField,
                    orderDirection,
                    query,
                    onlyMyCollections,
                    onlyParseCollections,
                )}
                onHeaderClick={updateOrderField}
                onAssignAnnotatorClick={collection => {
                    setSelectedCollection(collection)
                    setModalAssignCollectionOpen(true)
                }} />
            
            <AssignCollectionModal
                open={modalAssignCollectionOpen}
                setOpen={setModalAssignCollectionOpen}
                collection={selectedCollection} />
        
        </div>
        
    )
    
}

export default Collections
