import useServiceUsageReportsViewModel from './ServiceUsageReportsViewModel'
import useOrgsSearch from '@hook/useOrgsSearch'
import AutocompleteInput from '@components/shared/AutocompleteInput/AutocompleteInput'
import { MdFileDownload } from 'react-icons/md'

const ServiceUsageReports = () => {
    
    const vm = useServiceUsageReportsViewModel()
    const orgsSearch = useOrgsSearch()
    
    return (
        
        <div className="ServiceUsageReports flex flex-col gap-4">
            
            <header className="flex items-baseline justify-between content-baseline gap-6">
                <h4>Service Usage Reports</h4>
            </header>
            
            <div className="flex items-center content-center gap-4">
                
                <label className="relative form-control w-full max-w-xs">
                    <div className="label">
                        <span className="label-text">Organization</span>
                    </div>
                    {orgsSearch.hasOrgs
                        ? <AutocompleteInput
                            items={[ vm.ORG_NONE, ...orgsSearch.orgs.data ]}
                            onChange={vm.setSelectedOrganization}/>
                        : <p><i>Fetching organizations...</i></p>
                    }
                </label>
                
                <label className="form-control w-full max-w-xs">
                    <div className="label">
                        <span className="label-text">Action or Event</span>
                    </div>
                    {vm.actionOrEventTypesIsLoading
                        ? <p><i>Fetching action or event types...</i></p>
                        : (
                            <select
                                className="select select-bordered select-xs"
                                value={vm.selectedActionOrEvent}
                                onChange={e => vm.setSelectedActionOrEvent(e.target.value)}>
                                <option value="">All</option>
                                {vm.actionOrEventTypes?.rows?.map(it => (
                                    <option key={it} value={it}>{it}</option>
                                ))}
                            </select>
                        )
                    }
                </label>
                
                <label className="form-control w-full max-w-xs">
                    <div className="label">
                        <span className="label-text">Year</span>
                    </div>
                    <select
                        className="select select-bordered select-xs"
                        value={vm.selectedYear}
                        onChange={e => vm.setSelectedYear(e.target.value)}>
                        {vm.YEARS.map(it => (
                            <option key={it} value={it}>{it}</option>
                        ))}
                    </select>
                </label>
                
                <label className="form-control w-full max-w-xs">
                    <div className="label">
                        <span className="label-text">Month</span>
                    </div>
                    <select
                        className="select select-bordered select-xs"
                        value={vm.selectedMonth}
                        onChange={e => vm.setSelectedMonth(e.target.value)}>
                        {Object.keys(vm.MONTHS).map(it => (
                            <option key={vm.MONTHS[it]} value={it}>{vm.MONTHS[it]}</option>
                        ))}
                    </select>
                </label>
            
            </div>
            
            <div className="flex justify-around items-center content-center gap-4
                p-3 bg-accent/30 text-accent-content rounded">
                
                <div>
                    <div className="stat">
                        <div className="stat-title">Total</div>
                        <div className="stat-value text-2xl">
                            <code>${parseFloat(vm.report?.total || '0').toFixed(2)}</code>
                        </div>
                    </div>
                </div>
                
                <div className="flex items-center content-center gap-2">
                    <button
                        className="btn btn-primary"
                        onClick={vm.downloadReport}>
                        <MdFileDownload />
                        DOWNLOAD REPORT
                    </button>
                </div>
            
            </div>
            
            <table className="table table-auto w-full">
                <thead>
                    <tr>
                        <th>Organization</th>
                        <th>Action/Event</th>
                        <th>Count</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {vm.report?.rows?.map(it => (
                        <tr key={it.id}>
                            <td>{it.organization?.name}</td>
                            <td>{it.actionOrEventName}</td>
                            <td>{it.count}</td>
                            <td><code>${parseFloat(it.cost).toFixed(2)}</code></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
            {/* <div className="flex items-center content-center gap-4">
                <pre><code>{JSON.stringify({
                    // orgsSearch,
                    selectedOrganization: vm.selectedOrganization,
                    selectedActionOrEvent: vm.selectedActionOrEvent,
                    report: vm.report,
                }, null, 4)}</code></pre>
            </div> */}
        
        </div>
        
    )
    
}

export default ServiceUsageReports
