import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI, { publicApi } from '@api/ParseAPI'
import { createQueryString } from '@utils'
import { saveMergedUserLocal } from '@store'

const log = new Logger('actions/user')

const storeKey = 'user'

export const fetchProfile = async (fields = []) => {
    
    if (store.isFetchingUser.getValue()) return
    
    store.isFetchingUser.setValue(true)
    
    let result = null
    
    try {
        
        const query = fields.length ? createQueryString({ fields }) : ''
        
        const res = await ParseAPI.get(`users/profile${query}`)
        
        saveMergedUserLocal(res.data)
        
        localStorage.setItem('userId', res.data.id)
        localStorage.setItem('userEmail', res.data.email)
        
        result = res.data
        
    } catch (e) {
        
        log.e('fetchProfile', e)
        
    }
    
    store.isFetchingUser.setValue(false)
    
    return result
    
}

export const fetchUser = async email => {
    
    if (store.isFetchingUser.getValue()) return
    
    store.isFetchingUser.setValue(true)
    
    try {
        
        const res = await publicApi.get(`users/${email}`)
        
        saveMergedUserLocal(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
    store.isFetchingUser.setValue(false)
    
}

export const fetchUsers = async () => {
    
    try {
        
        const res = await ParseAPI.get('users')
        
        store.users.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateUser = async (email, user, roleId, subscriptionId) => {
    
    try {
        
        const res = await ParseAPI.post('users', {
            email: email,
            user: user,
            roleId,
            subscriptionId,
        })
        
        store.arrayUpdateById(storeKey, res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const removeUser = async id => {
    
    try {
        
        const res = await ParseAPI.delete(`users/${id}`)
        
        log.w('@todo removeUser', res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateLocalCredits = async () => {
    
    try {
        
        const res = await ParseAPI.get('users/credits')
        
        store.credits.setValue(res.data)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const resetUserPassword = async (id, currentPassword, newPassword, comfirmNewPassword) => {
    try {
        
        const res = await ParseAPI.post(`users/resetpassword/${id}`, {
            currentPassword,
            newPassword,
            comfirmNewPassword,
        })
        
        return res
        
    } catch (error) {
        
        log.e(error)
        
    }
}
