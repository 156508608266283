import { useState } from 'react'
import * as actions from '$actions'
import { useQuery, useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { createSyntheticDownload } from '@utils'

const YEARS = Array.from({ length: 3 }, (_, i) => new Date().getFullYear() - i)
const MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
}
const ORG_NONE = { id: null, name: 'None' }

const ServiceUsageReportsViewModel = () => {
    
    const [selectedOrganization, setSelectedOrganization] = useState(ORG_NONE)
    const [selectedActionOrEvent, setSelectedActionOrEvent] = useState('')
    const [selectedYear, setSelectedYear] = useState(YEARS[0])
    const [selectedMonth, setSelectedMonth] = useState(1)
    
    const {
        data: actionOrEventTypes,
        error: actionOrEventTypesError,
        isLoading: actionOrEventTypesIsLoading,
    } = useQuery({
        queryKey: ['actionOrEventTypes'],
        queryFn: actions.getServiceUsageTrackingsActionOrEventTypes,
        enabled: true,
        retry: 0,
    })
    
    const {
        data: report,
        error: reportError,
        isLoading: reportIsLoading,
    } = useQuery({
        queryKey: ['report', selectedOrganization, selectedActionOrEvent, selectedYear, selectedMonth],
        queryFn: () => actions.getServiceUsageReport(
            selectedOrganization?.id,
            selectedActionOrEvent,
            selectedYear,
            selectedMonth,
            'createdAt',
            'DESC',
        ).then(res => res.data),
        enabled: true,
        retry: 0,
    })
    
    const { mutate: downloadReport } = useMutation({
        queryKey: ['reportDownload', selectedOrganization, selectedActionOrEvent, selectedYear, selectedMonth],
        mutationFn: () => actions.getServiceUsageReport(
            selectedOrganization?.id,
            selectedActionOrEvent,
            selectedYear,
            selectedMonth,
            'createdAt',
            'DESC',
            true,
        ),
        onSuccess: res => {
            
            try {
                
                createSyntheticDownload(res.data, res.headers['content-type'], res.headers['content-name'])
                
                console.log('Service usage report downloaded')
                toast.success('Service usage report downloaded')
                
            } catch (e) {
                
                console.error('Failed to create synthetic download', e)
                toast.error('Failed to download document.')
                
            }
            
        },
        onError: e => {
            console.error('Error adding todo:', e)
            toast.error('Failed to download service usage report')
        },
    })
    
    return {
        
        // Constants
        YEARS,
        MONTHS,
        ORG_NONE,
        
        // State
        selectedOrganization,
        setSelectedOrganization,
        
        selectedActionOrEvent,
        setSelectedActionOrEvent,
        
        selectedYear,
        setSelectedYear,
        
        selectedMonth,
        setSelectedMonth,
        
        // Derived State
        actionOrEventTypes,
        actionOrEventTypesError,
        actionOrEventTypesIsLoading,
        report,
        reportError,
        reportIsLoading,
        
        // Methods,
        downloadReport,
        
    }
    
}

export default ServiceUsageReportsViewModel
