import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'

const SelectDatasetDocumentsModal = ({ selectDocumentsModal, setSelectDocumentsModal, 
    dataSetDocuments, markedDocuments, setMarkedDocuments }) => {
    
    // const [markedDocuments, setMarkedDocuments] = useState(dataSetDocuments.map(pd => pd.id))
    
    // useEffect(() => {
    //     setMarkedDocuments(dataSetDocuments.map(pd => pd.id))
    // }, [dataSetDocuments])
    
    return (
        <CustomModal
            open={() => selectDocumentsModal}
            modalHandler={() => setSelectDocumentsModal(!selectDocumentsModal)}>
            <ModalHeader>
            Select the documents to process
            </ModalHeader>
            <ModalBody>
                <table className="table table-pin-rows">
                    <thead>
                        <tr>
                            <th>Process Document</th>
                            <th>Document Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataSetDocuments.map(dsDoc => {
                            return (
                                <tr key={dsDoc.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={markedDocuments.includes(dsDoc.id)}
                                            onChange={event => {
                                                if (event.target.checked)
                                                    setMarkedDocuments(prevToProcess =>
                                                        [...prevToProcess, dsDoc.id])
                                                else
                                                    setMarkedDocuments(prevToProcess => prevToProcess
                                                        .filter(id => id !== dsDoc.id))
                                            }}/>
                                    </td>
                                    <td>{dsDoc.name}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <button 
                    className='btn btn-primary'
                    onClick={() => setSelectDocumentsModal(!selectDocumentsModal)}>
                Save
                </button>
            </ModalFooter>
        </CustomModal>
        
    )
}

export default SelectDatasetDocumentsModal