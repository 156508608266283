import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import * as actions from '$actions'
import * as store from '$store'
import { ST_VAL_EXT_KEY } from '@/constants/exampleManager'
import { useSearchParams } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import { toast } from 'react-toastify'
import CreatePresetModal from './CreatePresetModal'
import CreateTemplateModal from './CreateTemplateModal'
import ExampleManagerTabs from './ExampleManagerTabs'
import { ST_CHAINER_KEY } from '@/constants/exampleManager'

import { MdAdd } from 'react-icons/md'
import Toggle from '$components/Forms/Toggle'
import { FaTrash } from 'react-icons/fa'
import ConfirmationModal from '@/components/shared/ConfirmationModal'
import ExampleModal from './ExampleModal'

const DEFAULT_DEFINITION = { key: '', displayName: '', definition: '', visible: true }

const CHAINER_DEFINITION = [
    { key: 'get_document_content', definition: '' },
    { key: 'get_current_ownership', definition: '' },
    { key: 'update_ownership', definition: '' },
]

const DashboardExampleManager = () => {
    
    const [searchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    
    const [includeSubTabModal, setIncludeSubTabModal] = useState(false)
    const [showCreatePreset, setShowCreatePreset] = useState(false)
    const [isInsertingToLiveTable, setIsInsertingToLiveTable] = useState(false)
    
    const [showExampleManagerModal, setShowExampleManagerModal] = useState(false)
    const [, setSearchParams] = useSearchParams()
    
    const system = useMemo(() => searchParams.get('system') || ST_VAL_EXT_KEY, [searchParams.get('system')])
    const templateId = useMemo(() => searchParams.get('templateId'), [searchParams.get('templateId')])
    
    const systemTemplates = useWireValue(store.systemTemplates)
    
    const activeSystemTemplates = systemTemplates.filter(sys => sys.system === system)
    const selectedTemplate = activeSystemTemplates.find(tmp => tmp.id === templateId)
    
    const [shouldDeleteTemplate, setShouldDeleteTemplate] = useState(false)
    const [templateName, setTemplateName] = useState(selectedTemplate?.name)
    
    const [templateCollectionsName, setTemplateCollectionsName] = useState([])
    const [templateOrganizationsName, setTemplateOrganizationsName] = useState([])
    
    const [instruction, setInstruction] = useState(selectedTemplate?.instructions || '')
    const [definitions, setDefinitions] = useState([DEFAULT_DEFINITION])
    
    const [chainerConstants, setChainerConstants] = useState(CHAINER_DEFINITION)
    
    const loadFromLiveTable = async () => {
        setIsInsertingToLiveTable(true)
        await actions.loadFromLiveTable(system, templateId)
        setIsInsertingToLiveTable(false)
        
    }
    
    const deleteTemplate = async () => {
        
        try {
            
            await actions.deleteTemplate(templateId)
            
            setSearchParams(prev => {
                
                prev.set('templateId', activeSystemTemplates[0].id)
                
                return prev
            })
            
        } catch (err) {
            
            toast.error('Failed to delete template')
            
            console.log(err)
            
        }
        
    }
    
    const createDefinition = async () => {
        
        const defToInsert = definitions.filter(d => d.key)
        
        if (system !== 'chainer') {
            
            const { definitions: savedDefinitions } = await actions.saveInstructionAndDefinitions(
                instruction, templateName, /*baseTemplate*/ null, defToInsert, system, templateId)
            
            if (!savedDefinitions.length) {
                setDefinitions([DEFAULT_DEFINITION])
            } else {
                setDefinitions(savedDefinitions)
            }
            
            toast.success('Successfully updated Instructions and Definitions')
        } else {
            const { definitions: savedDefinitions } = await actions.saveInstructionAndDefinitions(
                instruction, templateName, null, chainerConstants, system, templateId)
            
            setChainerConstants(savedDefinitions)
            
            toast.success('Successfully updated Instructions and Definitions')
        }
        
    }
    
    const handleDefinitionValue = (key, idx, value) => {
        definitions[idx] = {
            ...definitions[idx],
            [key]: value,
        }
        const newArr = [...definitions]
        
        setDefinitions(newArr)
    }
    
    const addNewDefinition = () => {
        setDefinitions([...definitions, DEFAULT_DEFINITION])
    }
    
    const deleteDefinition = async (definitionId, idx) => {
        
        if (definitionId) {
            
            await actions.deleteDefinition(definitionId)
            setDefinitions(definitions.filter(def => def.id !== definitionId))
            
        } else {
            
            definitions.splice(idx, 1)
            setDefinitions([...definitions])
            
        }
        
    }
    
    useEffect(() => {
        
        actions.getExamples()
        actions.getTemplates()
        
    }, [])
    
    useEffect(() => {
        if (!templateId)
            return
        
        actions.findInstructionAndDefinitions(templateId).then(data => {
            
            setInstruction(data.template.instructions)
            
            if (system === ST_CHAINER_KEY) {
                
                const chainerDefinitions = CHAINER_DEFINITION.map(cd =>
                    data.definitions.find(dd => dd.key === cd.key) || cd)
                
                setChainerConstants(chainerDefinitions)
                
            } else {
                
                setDefinitions(data.definitions.length ? data.definitions : [DEFAULT_DEFINITION])
                
            }
            
        })
        
        actions.getTemplateCollections(templateId).then(res => {
            setTemplateCollectionsName(res)
        })
        
        actions.getTemplateOrganizations(templateId).then(res => {
            setTemplateOrganizationsName(res)
        })
        
    }, [templateId])
    
    return (
        
        <div className="p-4 w-full">
            <header>
                <h3>Template Manager</h3>
            </header>
            <div>
                <ExampleManagerTabs 
                    setIncludeSubTabModal={setIncludeSubTabModal} 
                    setTemplateName={setTemplateName}
                    origin='templateManager'/>
                
                <div className="flex flex-col">
                    <div className="flex gap-4 mt-4 w-full justify-between">
                        <div>
                            <button
                                className="btn btn-link"
                                onClick={() => setShowExampleManagerModal(true)}>
                                Edit Examples
                            </button>
                        </div>
                        
                        <div className="flex flex-row gap-2">
                            <button
                                className="btn btn-primary"
                                onClick={() => setShowCreatePreset(true)}> Create Version </button>
                            <div className="divider divider-horizontal m-0"></div>
                            <button 
                                className="btn btn-primary"
                                disabled={isInsertingToLiveTable}
                                onClick={() => loadFromLiveTable()}> Load from LiveTable </button>
                        
                        </div>
                    </div>
                    
                    <div className="flex gap-2 mt-4 bg-base-200 p-2 rounded">
                        <div className={cn('flex flex-col gap-4 w-full', {
                            'flex-1': system === 'value_extractor' || system === 'chainer',
                        })}>
                            <label htmlFor="instruction">Instructions</label>
                            <textarea
                                id="instruction"
                                type="textarea"
                                className="input input-bordered w-full h-[calc(100vh-280px)]"
                                value={instruction}
                                onChange={e => setInstruction(e.target.value)}></textarea>
                        </div>
                        
                        {system === 'chainer' && (
                            <div className="flex-1">
                                <div className="flex flex-row gap-4 justify-between w-full mb-4">
                                    <label htmlFor="definitions"> Definitions </label>
                                </div>
                                
                                <div className="overflow-auto h-[calc(100vh-280px)]">
                                    {chainerConstants.map((definition, idx) => {
                                        return (
                                            <div key={definition?.id} className="bg-base-100 rounded mb-2 p-4">
                                                <div className="flex justify-around gap-4">
                                                    <div className="flex flex-col w-4/12">
                                                        <label htmlFor="vname"> Tool Name </label>
                                                        <input
                                                            id="vname"
                                                            type="text"
                                                            className="input input-bordered w-full"
                                                            value={definition?.key}
                                                            disabled={true} />
                                                    </div>
                                                    <div className="flex flex-col self-center w-8/12">
                                                        <label htmlFor="def"> Tool Instructions </label>
                                                        <textarea
                                                            id="def"
                                                            className="input input-bordered h-20 w-full"
                                                            value={definition?.definition}
                                                            onChange={e => {
                                                                chainerConstants[idx] = {
                                                                    ...definition,
                                                                    definition: e.target.value,
                                                                }
                                                                const newArr = [...chainerConstants]
                                                                
                                                                setChainerConstants(newArr)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        
                        {system === 'value_extractor' && (
                            <div className="flex-1 ">
                                <div className="flex flex-row gap-4 justify-between w-full mb-1">
                                    <label htmlFor="definitions"> Definitions </label>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => addNewDefinition()}> <MdAdd /> Add Definition </button>
                                </div>
                                
                                <div className="overflow-auto h-[calc(100vh-280px)]">
                                    {definitions.map((definition, idx) => (
                                        <div key={definition.id || idx} className="bg-base-100 rounded mb-2 p-4">
                                            <div className="flex justify-around gap-4">
                                                <div className="flex flex-col gap-2">
                                                    <div className="flex flex-col">
                                                        <label htmlFor="vname"> Value Name </label>
                                                        <input
                                                            id="vname"
                                                            type="text"
                                                            className="input input-bordered w-full"
                                                            value={definition.key}
                                                            onChange={e =>
                                                                handleDefinitionValue('key', idx, e.target.value)} />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <label htmlFor="dname"> Display Name </label>
                                                        <input
                                                            id="dname"
                                                            type="text"
                                                            className="input input-bordered w-full"
                                                            value={definition.displayName}
                                                            onChange={e =>
                                                                handleDefinitionValue('displayName',
                                                                    idx, e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col self-center w-8/12">
                                                    <div className="flex">
                                                        <Toggle label="Visible"
                                                            checked={definition.visible}
                                                            className="gap-2 cursor-pointer mb-2"
                                                            onChange={visible =>
                                                                handleDefinitionValue('visible', idx, visible)} />
                                                    </div>
                                                    <label htmlFor="def"> Definition </label>
                                                    <textarea
                                                        id="def"
                                                        className="input input-bordered h-20 w-full"
                                                        value={definition.definition}
                                                        onChange={e =>
                                                            handleDefinitionValue('definition', idx, e.target.value)} />
                                                </div>
                                                <button
                                                    onClick={() => deleteDefinition(definition.id, idx)}
                                                    className='cursor-pointer text-red-400
                                                        hover:text-red-600 self-start'>
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    ),
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex gap-2 w-full bg-base-200 justify-end pb-2 mb-4'>
                        <button
                            className='btn btn-error'
                            onClick={() => setShouldDeleteTemplate(true)}>
                                Delete
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => createDefinition()}>
                                Update
                        </button>
                    </div>
                
                </div>
            </div>
            
            {shouldDeleteTemplate && (
                <ConfirmationModal
                    showModal={shouldDeleteTemplate}
                    showHideModal={() => setShouldDeleteTemplate(false)}
                    title="Delete Template"
                    message={(
                        <div>
                            <div>Are you sure you want to delete the template "{templateName}"? Be aware if the 
                            template is deleted, all examples under this template will be deleted as well.
                            
                            <p className='block mt-4'>
                                Collections that currently use this template:  
                            </p>
                            {templateCollectionsName.length ? (
                                <ul>
                                    {templateCollectionsName.map(tcn => (
                                        <li className='list-disc ml-5'>{tcn}</li>
                                    ))} 
                                </ul>
                            ) : ('Currently no collection uses this template.') }
                            
                            <p className='block mt-4'>
                                And these are the organizations that currently use this template:
                            </p>
                            {templateOrganizationsName.length ? (
                                <ul>
                                    {templateOrganizationsName.map(tcn => (
                                        <li className='list-disc ml-5'>{tcn}</li>
                                    ))}
                                </ul>
                            ) : (
                                'Currently no organization uses this template.'
                            )}
                            
                            </div>
                        </div>
                    )}
                    onConfirm={() => deleteTemplate()} />
            )}
            
            {showExampleManagerModal && (
                <ExampleModal 
                    showExampleManagerModal={showExampleManagerModal}
                    setShowExampleManagerModal={setShowExampleManagerModal}
                    system={system}
                    templateId={templateId}/>
            )}
            
            {showCreatePreset && (
                <CreatePresetModal
                    showCreatePreset={showCreatePreset}
                    setShowCreatePreset={setShowCreatePreset}
                    templateId={templateId}
                    system={system} />
            )}
            
            {includeSubTabModal && (
                <CreateTemplateModal
                    includeSubTabModal={includeSubTabModal}
                    setIncludeSubTabModal={setIncludeSubTabModal}
                    system={system}/>
            )}
        
        </div >
    )
}

export default DashboardExampleManager