import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MdOutlineAddBox } from 'react-icons/md'
import { useWireValue } from '@forminator/react-wire'
import cn from 'classnames'
import * as store from '$store'
import * as $store from '@store'

import * as actions from '$actions'
import { ST_VAL_EXT_KEY, SYSTEM_TYPES } from '@/constants/exampleManager'
import { DOCUMENT_TYPES } from '@/constants'
import { Selectbox } from '@/components/shared/Selectbox'

const ExampleManagerTabs = ({ setIncludeSubTabModal, setTemplateName = null, origin = null }) => {
    
    const remoteConfig = useWireValue($store.remoteConfig)
    
    const [searchParams, setSearchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    const systemTemplates = useWireValue(store.systemTemplates)
    
    const system = useMemo(() => searchParams.get('system') || ST_VAL_EXT_KEY, [searchParams.get('system')])
    const templateId = useMemo(() => searchParams.get('templateId'), [searchParams.get('templateId')])
    
    const models = useMemo(() => {
        if (!remoteConfig.modelsPerSystem?.length) return []
        
        return remoteConfig.modelsPerSystem.filter(model => model.systems.some(s => s === system))
    }, [remoteConfig.modelsPerSystem, system])
    
    const currentTemplates = useMemo(() =>
        systemTemplates.filter(st => st.system === system).sort((a, b) => a.name.localeCompare(b.name)),
    [systemTemplates, system])
    
    const selectedTemplate = useMemo(() =>
        currentTemplates.find(ct => ct.id === templateId)
    , [currentTemplates, templateId])
    
    const templateOption = selectedTemplate ? {
        value: selectedTemplate.id,
        label: selectedTemplate.name,
    } : null
    
    const modelOption = selectedTemplate ? {
        value: selectedTemplate.id,
        label: selectedTemplate.modelName,
    } : null
    
    const saveTemplateDocumentType = documentType =>
        actions.editTemplate(templateId, { documentType })
    
    const saveModelToVersion = model => {
        actions.saveModel(templateId, model,'template')
    }
    
    useEffect(() => {
        
        if (!currentTemplates?.[0]) return
        
        setSearchParams(prev => {
            // if its already a valid template, then i dont need to reset it
            if (currentTemplates.some(ct => ct.id === prev.get('templateId')))
                return prev
            
            prev.set('templateId', currentTemplates[0].id)
            return prev
        })
        
    }, [system, currentTemplates])
    
    return (
        <div className="flex flex-col">
            <div role="tablist" className="tabs tabs-boxed mt-4 shadow-md w-fit justify-start">
                {SYSTEM_TYPES.map(tab =>
                    <a
                        key={tab.key}
                        role="tab"
                        className={cn('tab whitespace-nowrap', { 'tab-active': system === tab.key })}
                        onClick={() => {
                            if (tab.key === system) return
                            
                            setSearchParams(prev => {
                                prev.set('system', tab.key)
                                prev.delete('templateId')
                                return prev
                            })
                        }}>
                        {tab.value}
                    </a>,
                )}
            </div>
            <div className="flex flex-row gap-2 mt-4">
                <div className="flex flex-col gap-4">
                    <div className='flex flex-col'>
                        <label htmlFor="template"> Template</label>
                        <div className='flex'>
                            {setIncludeSubTabModal && 
                                <a className="btn btn-secondary join-item cursor-pointer flex items-center
                                    px-2 tooltip tooltip-bottom-right"
                                data-tip="Add a new Template"
                                onClick={() => setIncludeSubTabModal(true)}>
                                    <MdOutlineAddBox className="text-xl" />
                                </a>}
                            
                            <Selectbox
                                className='w-[200px] react-tiny-popover-container' 
                                handleChange={(ev, value) => {
                                    setSearchParams(prev => { {
                                        prev.set('templateId', value.value)
                                        return prev
                                    } })
                                    setTemplateName(value.label)
                                }}
                                name="templates"
                                option={templateOption}
                                options={currentTemplates.map(tmpl => ({
                                    key: tmpl.id,
                                    value: tmpl.id,
                                    label: tmpl.name,
                                }))}/>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        
                        <div>
                            <label htmlFor="documentType">Document Type</label>
                            {selectedTemplate &&
                                
                                <Selectbox
                                    className='w-[255px] ' 
                                    handleChange={(ev, value) => {
                                        saveTemplateDocumentType(value.value)
                                    }}
                                    name="docType"
                                    options={DOCUMENT_TYPES.map(dt => ({
                                        value: dt.id,
                                        key: dt.id,
                                        label: dt.label,
                                    }))}/>
                            }
                        </div>
                        {origin && (<div>
                            <label htmlFor='models'>
                                Model
                            </label>
                            <Selectbox
                                className='w-[255px]'
                                handleChange={(ev, value) => {
                                    saveModelToVersion(value.value)
                                }}
                                name="models"
                                option={modelOption}
                                options={models.map(dt => (
                                    {
                                        value: dt.id,
                                        key: dt.id,
                                        label: dt.label,
                                    }))}/>
                        
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExampleManagerTabs