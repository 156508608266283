import { FaPlus, FaMinus } from 'react-icons/fa'
import useTestServiceApiViewModel from './TestServiceApiViewModel'
import { v4 as uuidV4 } from 'uuid'
import Repeatable from '@components/shared/Repeatable/Repeatable'
import { FiHash } from 'react-icons/fi'

const TestServiceApi = ({
    serviceApiKeys,
}) => {
    
    const vm = useTestServiceApiViewModel(serviceApiKeys)
    
    return (<>
        
        <div className="TestServiceApi">
            
            <header className="flex items-baseline justify-between content-baseline mb-1">
                <h5>Test Service API</h5>
            </header>
            
            <table className="table table-auto w-full">
                <tbody>
                    <tr>
                        <th>Service:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={vm.service.id}
                                onChange={vm.onServiceChange}>
                                {serviceApiKeys?.map(it => (
                                    <option key={it.id} value={it.id}>
                                        {it.name}{it.organization?.name ? ` / ${it.organization?.name}` : ''}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Endpoint:</th>
                        <td>
                            <input
                                className="input input-bordered input-sm"
                                type="text"
                                placeholder="demo"
                                value={vm.endpoint}
                                onChange={e => vm.setEndpoint(e.target.value)}/>
                        </td>
                    </tr>
                    <tr>
                        <th>Method:</th>
                        <td>
                            <select
                                className="select select-bordered select-sm"
                                value={vm.method}
                                onChange={e => vm.setMethod(e.target.value)}>
                                <option value="POST">POST</option>
                                <option value="GET">GET</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>File:</th>
                        <td>
                            <input
                                className="input input-bordered input-sm"
                                type="text"
                                placeholder="file"
                                value={vm.fileFieldName}
                                onChange={e => vm.setFileFieldName(e.target.value)}/>
                            <input
                                className="file-input input-sm"
                                type="file"
                                onChange={e => vm.setFile(e.target.files?.[0])}/>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <div className="w-auto flex !justify-end items-center content-center gap-4">
                                <div>Extra Fields</div>
                                <div className="w-auto">
                                    <button
                                        className="btn btn-sm btn-square btn-secondary"
                                        onClick={vm.extraFieldsRepeatableViewModel.onAddItemClick}>
                                        <FaPlus className="!ml-0"/>
                                    </button>
                                </div>
                            </div>
                        </th>
                        <td>
                            <Repeatable viewModel={vm.extraFieldsRepeatableViewModel}>
                                {(key, value, i, onKeyChange, onValueChange) => (
                                    <div key={`extra-field-${i}`} className="flex items-center content-center gap-2">
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="key"
                                            value={key}
                                            onChange={e => onKeyChange(i, e)} />
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="value"
                                            value={value}
                                            onChange={e => onValueChange(i, e)} />
                                        <button
                                            className="btn btn-sm btn-square btn-warning"
                                            onClick={() => vm.extraFieldsRepeatableViewModel.onRemoveItemClick(i)}>
                                            <FaMinus className="!ml-0"/>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-square btn-secondary"
                                            title="Generate a random UUID"
                                            onClick={() => onValueChange(i, { target: { value: uuidV4() } })}>
                                            <FiHash className="!ml-0"/>
                                        </button>
                                    </div>
                                )}
                            </Repeatable>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <div className="w-auto flex !justify-end items-center content-center gap-4">
                                <div>Extra Headers</div>
                                <div>
                                    <button
                                        className="btn btn-sm btn-square btn-secondary"
                                        onClick={vm.extraHeadersRepeatableViewModel.onAddItemClick}>
                                        <FaPlus className="!ml-0"/>
                                    </button>
                                </div>
                            </div>
                        </th>
                        <td>
                            <Repeatable viewModel={vm.extraHeadersRepeatableViewModel}>
                                {(key, value, i, onKeyChange, onValueChange) => (
                                    <div key={`extra-field-${i}`} className="flex items-center content-center gap-2">
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="key"
                                            value={key}
                                            onChange={e => onKeyChange(i, e)} />
                                        <input
                                            className="input input-bordered input-sm"
                                            type="text"
                                            placeholder="value"
                                            value={value}
                                            onChange={e => onValueChange(i, e)} />
                                        <button
                                            className="btn btn-sm btn-square btn-warning"
                                            onClick={() => vm.extraHeadersRepeatableViewModel.onRemoveItemClick(i)}>
                                            <FaMinus className="!ml-0"/>
                                        </button>
                                        <button
                                            className="btn btn-sm btn-square btn-secondary"
                                            title="Generate a random UUID"
                                            onClick={() => onValueChange(i, { target: { value: uuidV4() } })}>
                                            <FiHash className="!ml-0"/>
                                        </button>
                                    </div>
                                )}
                            </Repeatable>
                        </td>
                    </tr>
                    <tr>
                        <th>Summary</th>
                        <td>
                            <table className="table table-auto w-full">
                                <tbody>
                                    <tr>
                                        <td><b>Key:</b></td>
                                        <td>{vm.service?.key}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Endpoint:</b></td>
                                        <td>{vm.endpoint}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Target:</b></td>
                                        <td>{vm.url}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Token:</b></td>
                                        <td><span className="break-all">{vm.token}</span></td>
                                    </tr>
                                    <tr>
                                        <td><b>File:</b></td>
                                        <td>&quot;{vm.fileFieldName ?? 'N/A'}&quot; = {vm.file?.name ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Extra Fields:</b></td>
                                        <td>
                                            <ul className="list-disc pl-5">
                                                {vm.validExtraFields.map(it => {
                                                    
                                                    const key = Object.keys(it)[0]
                                                    const value = it[key]
                                                    
                                                    return (
                                                        <li key={key}><b>{key}:</b> {value}</li>
                                                    )
                                                    
                                                })}
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Extra Headers:</b></td>
                                        <td>
                                            <ul className="list-disc pl-5">
                                                {vm.validExtraHeaders.map(it => {
                                                    
                                                    const key = Object.keys(it)[0]
                                                    const value = it[key]
                                                    
                                                    return (
                                                        <li key={key}><b>{key}:</b> {value}</li>
                                                    )
                                                    
                                                })}
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div className="flex justify-center items-center content-center gap-2 mt-4">
                <button
                    className="btn btn-xl btn-success text-3xl w-1/4"
                    disabled={!vm.isReady}
                    onClick={vm.onTestServiceClick}>
                    TEST SERVICE
                </button>
            </div>
        
        </div>
    
    </>)
    
}

export default TestServiceApi
