import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
import { FaCheck, FaChevronDown } from 'react-icons/fa'
import { useMemo, useState } from 'react'

/**
 * Text input with autocomplete
 * 
 * @param {string} className
 * @param {ObjectWithIdAndName[]} items
 * @param {Function} onChange
 * @returns {JSX.Element}
 * @constructor
 */
const AutocompleteInput = ({
    items = [],
    onChange,
    queryKey = 'name',
} = {}) => {
    
    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState(items[1])
    
    const filteredItems = useMemo(() => (
        query === ''
            ? items
            : items.filter(item => {
                return item[queryKey].toLowerCase().includes(query.toLowerCase())
            })
    ), [query, items])
    
    return (
        
        <Combobox
            value={selected}
            onChange={value => {
                setSelected(value)
                onChange(value)
            }}
            onClose={() => setQuery('')}>
            
            <div className="relative w-full">
                
                <ComboboxInput
                    className="w-full py-1.5 pr-8 pl-3 text-sm/6
                        focus:outline-none data-[focus]:outline-2
                        data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    displayValue={item => item?.[queryKey]}
                    onChange={e => setQuery(e.target.value)} />
                
                <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                    <FaChevronDown className="size-4" />
                </ComboboxButton>
            
            </div>
            
            <ComboboxOptions
                transition
                className="absolute top-5 mt-10 h-60 overflow-auto w-min-content min-w-[var(--input-width)]
                    border border-base-300/40
                    bg-base-200 opacity-100 p-1 [--anchor-gap:var(--spacing-1)] empty:invisible
                    transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0">
                
                {filteredItems.map(item => (
                    <ComboboxOption
                        key={item.id}
                        value={item}
                        className="pb-2 px-2 data-[focus]:bg-base-300/40">
                        <FaCheck className="invisible size-4 fill-white group-data-[selected]:visible" />
                        <div className="text-sm/6">{item?.[queryKey]}</div>
                    </ComboboxOption>
                ))}
            
            </ComboboxOptions>
        
        </Combobox>
        
    )
    
}

export default AutocompleteInput
