import DashboardApp from '$/DashboardApp'
import Title from '@components/shared/Title'
import Home from '$routes/Home'
import Profile from '$routes/Profile'
import Users from '$routes/Users'
import DashboardOrganizations from '$routes/Organizations'
import Services from '$routes/Services'
import { Annotators, AnnotatorDetail } from '$routes/Annotators'
import DashboardCollections from '$routes/Collections'
import CollectionDetail from '$routes/Collections/CollectionDetail'
import Documents from '$routes/Documents'
import Queue from '$routes/Queue'
import AnnotationLabels from '$routes/AnnotationLabels'
import Firebase from '$routes/Firebase'
import ElasticSearch from '$routes/ElasticSearch'
import PagerDuty from '$routes/PagerDuty'
import CheckMappings from '$routes/CheckMappings'
import CheckImports from '$routes/CheckImports'
import UserActions from '$routes/UserActions'
import TrackingReport from '$routes/TrackingReport'
import CostAndRevenue from '$routes/CostAndRevenue'
import ExampleManager, { Presets, PresetEvaluationResult } from '$routes/ExampleManager'

const routes = [
    {
        path: '/dashboard',
        component: 'Dashboard',
        Component: Home,
        title: 'Dashboard',
    },
    {
        path: '/dashboard/profile',
        component: 'Profile',
        Component: Profile,
        title: 'Dashboard - Profile',
    },
    {
        path: '/dashboard/users',
        component: 'Users',
        Component: Users,
        title: 'Dashboard - Users',
    },
    {
        path: '/dashboard/organizations',
        component: 'DashboardOrganizations',
        Component: DashboardOrganizations,
        title: 'Dashboard - Organizations',
    },
    {
        path: '/dashboard/services',
        component: 'Services',
        Component: Services,
        title: 'Dashboard - Services',
    },
    {
        path: '/dashboard/annotators',
        component: 'Annotators',
        Component: Annotators,
        title: 'Dashboard - Annotators',
    },
    {
        path: '/dashboard/annotators/:id',
        component: 'AnnotatorDetail',
        Component: AnnotatorDetail,
        title: 'Dashboard - Annotator Detail',
    },
    {
        path: '/dashboard/collections',
        component: 'DashboardCollections',
        Component: DashboardCollections,
        title: 'Dashboard - Collections',
    },
    {
        path: '/dashboard/collections/:id',
        component: 'CollectionDetail',
        Component: CollectionDetail,
        title: 'Dashboard - Collection Detail',
    },
    {
        path: '/dashboard/documents',
        component: 'Documents',
        Component: Documents,
        title: 'Dashboard - Documents',
    },
    {
        path: '/dashboard/queue',
        component: 'Queue',
        Component: Queue,
        title: 'Dashboard - Queue',
    },
    {
        path: '/dashboard/labels',
        component: 'AnnotationLabels',
        Component: AnnotationLabels,
        title: 'Dashboard - Annotation Labels',
    },
    {
        path: '/dashboard/firebase',
        component: 'Firebase',
        Component: Firebase,
        title: 'Dashboard - Firebase',
    },
    {
        path: '/dashboard/elasticsearch',
        component: 'ElasticSearch',
        Component: ElasticSearch,
        title: 'Dashboard - ElasticSearch',
    },
    {
        path: '/dashboard/costandrevenue',
        component: 'CostAndRevenue',
        Component: CostAndRevenue,
        title: 'Dashboard - Cost And Revenue',
    },
    {
        path: '/dashboard/check-imports',
        component: 'CheckImports',
        Component: CheckImports,
        title: 'Dashboard - Check Imports',
    },
    {
        path: '/dashboard/check-mappings',
        component: 'CheckMappings',
        Component: CheckMappings,
        title: 'Dashboard - Check Mappings',
    },
    {
        path: '/dashboard/pagerduty',
        component: 'PagerDuty',
        Component: PagerDuty,
        title: 'Dashboard - Pager Duty',
    },
    {
        path: '/dashboard/user-tracking',
        component: 'UserActions',
        Component: UserActions,
        title: 'Dashboard - User Action Tracking',
    },
    {
        path: '/dashboard/tracking-report',
        component: 'TrackingReport',
        Component: TrackingReport,
        title: 'Dashboard - Tracking Report',
    },
    {
        path: '/dashboard/example-manager',
        component: 'ExampleManager',
        Component: ExampleManager,
        title: 'Dashboard - Template Manager',
    },
    {
        path: '/dashboard/example-manager/presets',
        component: 'Presets',
        Component: Presets,
        title: 'Version Manager',
    },
    {
        path: '/dashboard/example-manager/presets/evaluation-results/:id',
        component: 'PresetEvaluationResult',
        Component: PresetEvaluationResult,
        title: 'Evaluation Results',
    },
].map(it => ({
    ...it,
    section: 'dashboard',
    excludeTopNav: true,
}))

// Wrap all routes with the main Dashboard wrapper
export default routes.map(it => {
    
    const { Component, title } = it
    
    return {
        ...it,
        Component: () => (
            <DashboardApp>
                <Title>{title}</Title>
                <Component />
            </DashboardApp>
        ),
    }
    
})
