/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useMemo, useState } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import * as actions from '@actions'
import { RT_EVENTS_PUBLIC } from '@constants/realtime'
import NotificationCenterItem from './NotificationCenterItem'
import NotificationCenterIcon from './NotificationCenterIcon'
import cn from 'classnames'
import './NotificationCenter.css'

const debugForceMenuOpen = false

const NotificationCenter = () => {
    const notifications = useWireValue(store.notifications)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const itemRefs = useRef({})
    
    const publicNotifications = useMemo(() => 
        notifications?.filter(notification =>
            RT_EVENTS_PUBLIC.includes(notification.message.data.type),
        )
    , [notifications])
    
    const dismissAllVisibleNotifications = event => {
        event.stopPropagation()
        Object.values(itemRefs.current).forEach(ref => {
            if (ref && ref.dismiss) {
                ref.setIsDismissing()
            }
        })
        
        setTimeout(() => {
            actions.dismissAllVisibleNotifications()
        }, 300)
    }
    
    const toggleDropdown = () => setIsDropdownOpen(prev => !prev)
    
    useEffect(() => {
        const handleOutsideClick = event => {
            if (!document.getElementById('NotificationCenter')?.contains(event.target)) {
                setIsDropdownOpen(false)
            }
        }
        
        document.addEventListener('click', handleOutsideClick)
        return () => document.removeEventListener('click', handleOutsideClick)
    }, [])
    
    useEffect(() => {
        actions.fetchTopNotificationsByType()
    }, [])
    
    return (
        <div
            id="NotificationCenter"
            className={cn('dropdown dropdown-end', {
                'dropdown-open': debugForceMenuOpen || isDropdownOpen,
            })}>
            
            <div onClick={toggleDropdown}>
                <NotificationCenterIcon notifications={publicNotifications} />
            </div>
            
            <div 
                tabIndex={0} 
                className="dropdown-content"
                onClick={e => e.stopPropagation()}>
                <div className="p-4 border-b border-base-300">
                    <div className="flex justify-between items-center">
                        <span className="text-lg font-semibold">Notifications</span>
                        {!!publicNotifications.length && (
                            <button
                                className="text-sm text-primary underline"
                                onClick={dismissAllVisibleNotifications}>
                                Dismiss all
                            </button>
                        )}
                    </div>
                </div>
                
                <div className="wrapper">
                    {publicNotifications.length === 0 ? (
                        <div className="p-4 text-center">
                            <p>All Done!</p>
                            <p>Nothing to see here.</p>
                        </div>
                    ) : (
                        <ul>
                            {publicNotifications.map(notification => (
                                <NotificationCenterItem
                                    key={notification.message.data.id}
                                    ref={el => (itemRefs.current[notification.message.data.id] = el)}
                                    notification={notification}
                                    isDropdownOpen={isDropdownOpen} />
                            ))}
                        </ul>
                    )}
                </div>
                
                {/* @todo: We can implement this when a notification page exists */}
                {/* <div className="p-4 border-t border-base-300 text-center">
                    <button className="text-primary underline text-sm">
                        View all notifications
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default NotificationCenter
