import CustomModal, { ModalBody, ModalFooter } from '@/components/shared/CustomModal'
import * as store from '$store'
import { useWireValue } from '@forminator/react-wire'
import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import * as actions from '$actions'
import { ENABLED_STATUS, EXAMPLE_TYPES } from '@/constants/exampleManager'
import ExampleManagerModal from '../../../src/components/exampleManager/ExampleManagerModal'
import { toast } from 'react-toastify'

const ExampleModal = ({ showExampleManagerModal, setShowExampleManagerModal, system, templateId }) => {
    
    const examples = useWireValue(store.examples)
    
    const [markedDocuments, setMarkedDocuments] = useState([])
    const [showCreateExample, setShowCreateExample] = useState(false)
    const [example, setExample] = useState(null)
    
    const arrayToRender = useMemo(() => 
        examples.filter(ex => system === ex.system && templateId === ex.valueExtractorTemplateId),
    [examples, system, templateId])
    
    const deleteItems = async () => {
        await actions.deleteExamples(markedDocuments)
        toast.success(`Successfully removed ${markedDocuments} examples.`)
    }
    
    useEffect(() => {
        
        actions.getExamples()
        actions.getTemplates()
        
    }, [])
    
    return (
        <CustomModal
            open={showExampleManagerModal}
            modalHandler={() => setShowExampleManagerModal(false)}
            className="!max-w-[80vw] 2xl:!max-w-[60vw]">
            <ModalBody>
                {arrayToRender.length ? (<table className="mt-4 w-full">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Document Name</th>
                            <th>Type</th>
                            <th>Subtype</th>
                            <th>Description</th>
                            <th>Example Type</th>
                            <th>Enabled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrayToRender.map(example => {
                            return (
                                <tr key={example.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={markedDocuments.includes(example.id)}
                                            onChange={event => {
                                                if (event.target.checked)
                                                    setMarkedDocuments(prevToDelete =>
                                                        [...prevToDelete, example.id])
                                                else
                                                    setMarkedDocuments(prevToDelete => prevToDelete
                                                        .filter(id => id !== example.id))
                                            }} />
                                    </td>
                                    <td>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setShowCreateExample(true)
                                                setExample(example)
                                            }}>
                                            {example.document?.name}
                                        </div>
                                    </td>
                                    <td>{example.type}</td>
                                    <td>{example.subType}</td>
                                    <td>
                                        <div className="truncate">
                                            {example.description}
                                        </div>
                                    </td>
                                    <td>
                                        <select
                                            id="exampleType"
                                            className="select select-bordered"
                                            value={example.exampleType}
                                            onChange={async e => 
                                                await actions.changeExampleType(example.id, e.target.value)
                                            }>
                                            <option></option>
                                            {EXAMPLE_TYPES.map(it => (
                                                <option key={it.key} value={it.key}>
                                                    {it.value}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            id="enabled"
                                            className="select select-bordered"
                                            value={example.enabled}
                                            onChange={async e => 
                                                await actions.changeEnabledValue(example.id, e.target.value)
                                            }>
                                            <option></option>
                                            {ENABLED_STATUS.map(it => (
                                                <option key={it.key} value={it.key}>
                                                    {it.value}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) : (<h1 className="flex justify-center">there's no document on this template</h1>)}
            
            </ModalBody>
            <ModalFooter>
                {arrayToRender.length ? (<button
                    className={cn('btn btn-error', {
                        'opacity-20': !markedDocuments.length,
                    })}
                    onClick={() => {
                        deleteItems()
                        setMarkedDocuments([])
                    }}
                    disabled={markedDocuments.length === 0}> Delete 
                </button>) : null}
            </ModalFooter>
            
            {showCreateExample && (
                <ExampleManagerModal
                    show={showCreateExample}
                    handleClose={() => setShowCreateExample(false)}
                    example={example}
                    document={example.document} />
            )}
        </CustomModal>
        
    )
}

export default ExampleModal