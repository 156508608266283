export const ATR_PACKET_STATUS_STARTED = 'started'
export const ATR_PACKET_STATUS_EXTRACTING_DOCS = 'extracting_docs'
export const ATR_PACKET_STATUS_PROCESSING = 'processing'
export const ATR_PACKET_STATUS_EXTRACTION_COMPLETED = 'extraction_completed'
export const ATR_PACKET_STATUS_COMPLETED = 'completed'
export const ATR_PACKET_STATUS_EXTRACTION_FAILED = 'extraction_failed'
export const ATR_PACKET_STATUS_ALREADY_EXTRACTED = 'already_extracted'
export const ATR_PACKET_STATUS_FAILED = 'failed'

// ATR statuses v2
export const ATR_PACKET_RECEIVED = 'packet_received'
export const ATR_OCR_IN_PROGRESS = 'ocr_in_progress'
export const ATR_OCR_FAILED = 'ocr_failed'
export const ATR_OCR_COMPLETED = 'ocr_completed'
export const ATR_DOC_EXTRACTION_IN_PROGRESS = 'doc_extraction_progress'
export const ATR_DOC_EXTRACTION_COMPLETED = 'doc_extraction_completed'
export const ATR_DOC_EXTRACTION_FAILED = 'doc_extraction_failed'
export const ATR_VALUE_EXTRACTION_PROGRESS = 'value_extraction_progress'
export const ATR_VALUE_EXTRACTION_FAILED = 'value_extraction_failed'
export const ATR_VALUE_EXTRACTION_COMPLETED = 'value_extraction_completed'
export const ATR_TRANSACTION_COMPLETED = 'transaction_completed'
export const ATR_INPUT_VALIDATED = 'input_validated'
export const ATR_INPUT_INVALID = 'input_invalid'


export const ATR_PACKET_STATUSES = [
    ATR_PACKET_STATUS_STARTED,
    ATR_PACKET_STATUS_EXTRACTING_DOCS,
    ATR_PACKET_STATUS_PROCESSING,
    ATR_PACKET_STATUS_EXTRACTION_COMPLETED,
    ATR_PACKET_STATUS_COMPLETED,
    ATR_PACKET_STATUS_EXTRACTION_FAILED,
    ATR_PACKET_STATUS_ALREADY_EXTRACTED,
    ATR_PACKET_STATUS_FAILED,
    ATR_INPUT_VALIDATED,
    ATR_INPUT_INVALID,
]



// export const HUMAN_ATR_STATUS = {
//     [ATR_PACKET_STATUS_EXTRACTING_DOCS]: 'Extracting Documents',
//     [ATR_PACKET_STATUS_EXTRACTION_COMPLETED]: 'Extraction Completed',
//     [ATR_PACKET_STATUS_EXTRACTION_FAILED]: 'Extraction Failed',
//     [ATR_PACKET_STATUS_ALREADY_EXTRACTED]: 'Already Extracted',
//     [ATR_PACKET_STATUS_STARTED]: 'Started',
//     [ATR_PACKET_STATUS_PROCESSING]: 'Processing',
//     [ATR_PACKET_STATUS_COMPLETED]: 'Processing Completed',
//     [ATR_PACKET_STATUS_FAILED]: 'Processing Failed',
// }

export const HUMAN_ATR_STATUS = {
    [ATR_PACKET_RECEIVED]: 'Packet Received',
    [ATR_OCR_IN_PROGRESS]: 'Extracting Documents',
    [ATR_OCR_FAILED]: 'Extracting Documents Failed',
    [ATR_OCR_COMPLETED]: 'Extracting Documents',
    [ATR_DOC_EXTRACTION_IN_PROGRESS]: 'Extracting Documents',
    [ATR_DOC_EXTRACTION_COMPLETED]: 'Documents Extracted',
    [ATR_DOC_EXTRACTION_FAILED]: 'Failed to Extract Documents',
    [ATR_VALUE_EXTRACTION_PROGRESS]: 'Processing',
    [ATR_VALUE_EXTRACTION_FAILED]: 'Failed to Process',
    [ATR_INPUT_VALIDATED]: 'File Validated',
    [ATR_INPUT_INVALID]: 'Failed Validation',
}

// export const POLLING_ATR_STATUSES = [
//     ATR_PACKET_STATUS_STARTED,
//     ATR_PACKET_STATUS_EXTRACTING_DOCS,
//     ATR_PACKET_STATUS_PROCESSING,
// ]

export const POLLING_ATR_STATUSES = [
    ATR_PACKET_RECEIVED,
    ATR_OCR_IN_PROGRESS,
    ATR_OCR_FAILED,
    ATR_OCR_COMPLETED,
    ATR_DOC_EXTRACTION_IN_PROGRESS,
    ATR_DOC_EXTRACTION_COMPLETED,
    ATR_VALUE_EXTRACTION_PROGRESS,
    ATR_VALUE_EXTRACTION_FAILED,
    ATR_INPUT_VALIDATED,
    ATR_INPUT_INVALID,
]

export const CAN_PROCESS_ATR = [
    ATR_DOC_EXTRACTION_COMPLETED,
    ATR_VALUE_EXTRACTION_FAILED,
    ATR_INPUT_INVALID,
]